import YamlLoader from "admin/components/YamlLoader";
import ConfirmationButton from "components/ConfirmationButton";
import {
  deleteAllConcerts,
  importConcerts,
  exportConcerts,
} from "./batch-operations";

const ConcertBatchJobs = () => {
  const handleDeleteConcerts = async () => {
    await deleteAllConcerts();
  };

  const handleImportConcerts = async (data) => {
    await importConcerts(data);
  };

  const handleExportConcerts = async () => {
    await exportConcerts();
  };

  return (
    <div>
      <h3>Delete All Concerts</h3>
      <ConfirmationButton
        message="すべてのコンサートを削除します。"
        onOk={handleDeleteConcerts}
        variant="contained"
      >
        Delete
      </ConfirmationButton>

      <h3>Import Concerts</h3>
      <p>
        この操作は、orchestra, composer,
        pieceのidが固定されていることを前提としています。
      </p>
      <YamlLoader onLoad={handleImportConcerts} />

      <h3>Export Concerts</h3>
      <ConfirmationButton
        message="コンサートのデータをエクスポートします。"
        onOk={handleExportConcerts}
        variant="contained"
      >
        Export
      </ConfirmationButton>
    </div>
  );
};

export default ConcertBatchJobs;
