import React from "react";
import { Switch, Route } from "react-router-dom";
import AdminTop from "./pages/AdminTop";
import {
  ListComposers,
  RegisterComposer,
  ViewComposer,
  EditComposer,
} from "./pages/composers";
import {
  ListPieces,
  RegisterPiece,
  ViewPiece,
  EditPiece,
} from "./pages/pieces";
import {
  ListOrchestras,
  RegisterOrchestra,
  ViewOrchestra,
  EditOrchestra,
} from "./pages/orchestras";
import {
  ListConcerts,
  RegisterConcert,
  ViewConcert,
  EditConcert,
} from "./pages/concerts";
import BatchJobs from "./pages/batch-jobs";
import paths from "./paths";

const Routes = () => {
  return (
    <div>
      <Switch>
        <Route path={paths.listComposers()} exact>
          <ListComposers />
        </Route>
        <Route path={paths.registerComposer()} exact>
          <RegisterComposer />
        </Route>
        <Route path={paths.viewComposer()} exact>
          <ViewComposer />
        </Route>
        <Route path={paths.editComposer()} exact>
          <EditComposer />
        </Route>
        <Route path={paths.listPieces()} exact>
          <ListPieces />
        </Route>
        <Route path={paths.registerPiece()} exact>
          <RegisterPiece />
        </Route>
        <Route path={paths.viewPiece()} exact>
          <ViewPiece />
        </Route>
        <Route path={paths.editPiece()} exact>
          <EditPiece />
        </Route>
        <Route path={paths.listOrchestras()} exact>
          <ListOrchestras />
        </Route>
        <Route path={paths.registerOrchestra()} exact>
          <RegisterOrchestra />
        </Route>
        <Route path={paths.viewOrchestra()} exact>
          <ViewOrchestra />
        </Route>
        <Route path={paths.editOrchestra()} exact>
          <EditOrchestra />
        </Route>
        <Route path={paths.listConcerts()} exact>
          <ListConcerts />
        </Route>
        <Route path={paths.registerConcert()} exact>
          <RegisterConcert />
        </Route>
        <Route path={paths.viewConcert()} exact>
          <ViewConcert />
        </Route>
        <Route path={paths.editConcert()} exact>
          <EditConcert />
        </Route>
        <Route path={paths.batchJobs()} exact>
          <BatchJobs />
        </Route>
        <Route path={paths.adminRoot()}>
          <AdminTop />
        </Route>
      </Switch>
    </div>
  );
};

export default Routes;
