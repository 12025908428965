import yaml from "js-yaml";
import FileLoader from "./FileLoader";

const YamlLoader = ({ onLoad }) => {
  const handleLoad = (text) => {
    try {
      const data = yaml.load(text);
      onLoad(data);
    } catch (err) {
      console.error(err);
    }
  };
  return <FileLoader onLoad={handleLoad} />;
};

export default YamlLoader;
