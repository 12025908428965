/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const getComposer = /* GraphQL */ `
  query GetComposer($id: ID!) {
    getComposer(id: $id) {
      id
      nameJa
      longNameJa
      nameLatin
      longNameLatin
      pieces {
        items {
          id
          nameJa
          longNameJa
          nameEn
          longNameEn
          composerId
          composer {
            id
            nameJa
            longNameJa
            nameLatin
            longNameLatin
            pieces {
              nextToken
            }
            createdAt
            updatedAt
          }
          concerts {
            items {
              id
              concertId
              pieceId
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listComposers = /* GraphQL */ `
  query ListComposers(
    $filter: ModelComposerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComposers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nameJa
        longNameJa
        nameLatin
        longNameLatin
        pieces {
          items {
            id
            nameJa
            longNameJa
            nameEn
            longNameEn
            composerId
            composer {
              id
              nameJa
              longNameJa
              nameLatin
              longNameLatin
              createdAt
              updatedAt
            }
            concerts {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPiece = /* GraphQL */ `
  query GetPiece($id: ID!) {
    getPiece(id: $id) {
      id
      nameJa
      longNameJa
      nameEn
      longNameEn
      composerId
      composer {
        id
        nameJa
        longNameJa
        nameLatin
        longNameLatin
        pieces {
          items {
            id
            nameJa
            longNameJa
            nameEn
            longNameEn
            composerId
            composer {
              id
              nameJa
              longNameJa
              nameLatin
              longNameLatin
              createdAt
              updatedAt
            }
            concerts {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      concerts {
        items {
          id
          concertId
          pieceId
          concert {
            id
            orchestraId
            orchestra {
              id
              nameJa
              nameEn
              website
              createdAt
              updatedAt
            }
            nameJa
            nameEn
            program {
              nextToken
            }
            date
            venue
            venuePrefecture
            website
            note
            conductor
            createdAt
            updatedAt
          }
          piece {
            id
            nameJa
            longNameJa
            nameEn
            longNameEn
            composerId
            composer {
              id
              nameJa
              longNameJa
              nameLatin
              longNameLatin
              createdAt
              updatedAt
            }
            concerts {
              nextToken
            }
            createdAt
            updatedAt
          }
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPieces = /* GraphQL */ `
  query ListPieces(
    $filter: ModelPieceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPieces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nameJa
        longNameJa
        nameEn
        longNameEn
        composerId
        composer {
          id
          nameJa
          longNameJa
          nameLatin
          longNameLatin
          pieces {
            items {
              id
              nameJa
              longNameJa
              nameEn
              longNameEn
              composerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        concerts {
          items {
            id
            concertId
            pieceId
            concert {
              id
              orchestraId
              nameJa
              nameEn
              date
              venue
              venuePrefecture
              website
              note
              conductor
              createdAt
              updatedAt
            }
            piece {
              id
              nameJa
              longNameJa
              nameEn
              longNameEn
              composerId
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrchestra = /* GraphQL */ `
  query GetOrchestra($id: ID!) {
    getOrchestra(id: $id) {
      id
      nameJa
      nameEn
      website
      concerts {
        items {
          id
          orchestraId
          orchestra {
            id
            nameJa
            nameEn
            website
            concerts {
              nextToken
            }
            createdAt
            updatedAt
          }
          nameJa
          nameEn
          program {
            items {
              id
              concertId
              pieceId
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          date
          venue
          venuePrefecture
          website
          note
          conductor
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrchestras = /* GraphQL */ `
  query ListOrchestras(
    $filter: ModelOrchestraFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrchestras(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nameJa
        nameEn
        website
        concerts {
          items {
            id
            orchestraId
            orchestra {
              id
              nameJa
              nameEn
              website
              createdAt
              updatedAt
            }
            nameJa
            nameEn
            program {
              nextToken
            }
            date
            venue
            venuePrefecture
            website
            note
            conductor
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConcert = /* GraphQL */ `
  query GetConcert($id: ID!) {
    getConcert(id: $id) {
      id
      orchestraId
      orchestra {
        id
        nameJa
        nameEn
        website
        concerts {
          items {
            id
            orchestraId
            orchestra {
              id
              nameJa
              nameEn
              website
              createdAt
              updatedAt
            }
            nameJa
            nameEn
            program {
              nextToken
            }
            date
            venue
            venuePrefecture
            website
            note
            conductor
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nameJa
      nameEn
      program {
        items {
          id
          concertId
          pieceId
          concert {
            id
            orchestraId
            orchestra {
              id
              nameJa
              nameEn
              website
              createdAt
              updatedAt
            }
            nameJa
            nameEn
            program {
              nextToken
            }
            date
            venue
            venuePrefecture
            website
            note
            conductor
            createdAt
            updatedAt
          }
          piece {
            id
            nameJa
            longNameJa
            nameEn
            longNameEn
            composerId
            composer {
              id
              nameJa
              longNameJa
              nameLatin
              longNameLatin
              createdAt
              updatedAt
            }
            concerts {
              nextToken
            }
            createdAt
            updatedAt
          }
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      date
      venue
      venuePrefecture
      website
      note
      conductor
      createdAt
      updatedAt
    }
  }
`;
export const listConcerts = /* GraphQL */ `
  query ListConcerts(
    $filter: ModelConcertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConcerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orchestraId
        orchestra {
          id
          nameJa
          nameEn
          website
          concerts {
            items {
              id
              orchestraId
              nameJa
              nameEn
              date
              venue
              venuePrefecture
              website
              note
              conductor
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nameJa
        nameEn
        program {
          items {
            id
            concertId
            pieceId
            concert {
              id
              orchestraId
              nameJa
              nameEn
              date
              venue
              venuePrefecture
              website
              note
              conductor
              createdAt
              updatedAt
            }
            piece {
              id
              nameJa
              longNameJa
              nameEn
              longNameEn
              composerId
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        date
        venue
        venuePrefecture
        website
        note
        conductor
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
