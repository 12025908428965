import { useState } from "react";
import { Button } from "@material-ui/core";

const FileLoader = ({ onLoad }) => {
  const [file, setFile] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleClick = async () => {
    setProcessing(true);
    try {
      const content = await file.text();
      onLoad(content);
    } catch (err) {
      console.error(err);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleChange} disabled={processing} />
      <Button
        variant="outlined"
        onClick={handleClick}
        disabled={file == null || processing}
      >
        Load
      </Button>
    </div>
  );
};

export default FileLoader;
