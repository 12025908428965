import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { updateOrchestra } from "graphql/mutations";
import useOrchestra from "hooks/useOrchestra";
import OrchestraForm from "admin/components/OrchestraForm";
import QueryResultHandler from "components/QueryResultHandler";
import paths from "admin/paths";

const EditOrchestra = () => {
  const { id } = useParams();
  const history = useHistory();
  const { orchestra, ...queryResult } = useOrchestra(id);

  const handleSubmit = async (input) => {
    input.id = id;
    try {
      await API.graphql(graphqlOperation(updateOrchestra, { input }));
      history.push(paths.viewOrchestra(id));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <h1>Edit Orchestra</h1>

      <QueryResultHandler {...queryResult}>
        <OrchestraForm initialState={orchestra} onSubmit={handleSubmit} />
      </QueryResultHandler>
    </div>
  );
};

export default EditOrchestra;
