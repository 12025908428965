import useConcert from "hooks/useConcert";
import { Concert as ConcertPresentation } from "./components";

const Concert = ({ id }) => {
  const { concert, loading, errors } = useConcert(id);

  if (errors) {
    console.log(errors);
    return <div>Error</div>;
  }

  if (loading) return <div>Loading...</div>;

  return <ConcertPresentation concert={concert} />;
};

export default Concert;
