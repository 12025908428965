import { useParams } from "react-router-dom";
import Concert from "components/Concert";

const ConcertCalendarPage = () => {
  const { id } = useParams();

  return (
    <div>
      <Concert id={id} />
    </div>
  );
};

export default ConcertCalendarPage;
