import { makeStyles } from "@material-ui/core";
import { Footer } from "./Footer";

const useStyles = makeStyles((theme) => ({
  root: {},
  main: {
    minHeight: "85vh",
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  footer: {
    padding: theme.spacing(0, 1),
  },
}));

export const Layout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main className={classes.main}>{children}</main>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
};
