import { format, eachDayOfInterval, startOfMonth, endOfMonth } from "date-fns";

const getContentKey = (date) => format(date, "yyyy-MM-dd");

const DateHeader = ({ day }) => {
  return (
    <div style={{ marginTop: 12, borderBottom: "1px solid #ccc" }}>
      <span style={{ fontWeight: "bold" }}>{format(day, "d")}</span>
      <span style={{ marginLeft: 8, fontSize: "0.8rem" }}>
        {format(day, "(EEE)")}
      </span>
    </div>
  );
};

export const MonthlyCalendar = ({ targetMonth, contents }) => {
  const monthInterval = {
    start: startOfMonth(targetMonth),
    end: endOfMonth(targetMonth),
  };

  return (
    <div>
      {eachDayOfInterval(monthInterval).map((day) => (
        <div key={day.toString()}>
          <DateHeader day={day} />
          <div>{contents[getContentKey(day)]}</div>
        </div>
      ))}
    </div>
  );
};
