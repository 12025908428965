import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

const ConfirmationButton = React.forwardRef(
  ({ title, message, onOk, onCancel, children, ...props }, ref) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
      setOpen(true);
    };

    const handleOk = () => {
      setOpen(false);
      if (onOk != null) {
        onOk();
      }
    };

    const handleCancel = () => {
      setOpen(false);
      if (onCancel != null) {
        onCancel();
      }
    };

    return (
      <>
        <Dialog
          open={open}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-message"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-message">
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="default">
              Cancel
            </Button>
            <Button onClick={handleOk} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Button ref={ref} onClick={handleClick} {...props}>
          {children}
        </Button>
      </>
    );
  }
);

export default ConfirmationButton;
