import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import {
  updateConcert,
  createProgramPiece,
  deleteProgramPiece,
} from "graphql/mutations";
import useConcert from "hooks/useConcert";
import ConcertForm from "admin/components/ConcertForm";
import QueryResultHandler from "components/QueryResultHandler";
import paths from "admin/paths";

const EditConcert = () => {
  const { id } = useParams();
  const history = useHistory();
  const { concert, ...queryResult } = useConcert(id);

  const handleSubmit = async (input) => {
    input.id = id;
    try {
      const program = input.program;
      delete input.program;

      await API.graphql(graphqlOperation(updateConcert, { input }));

      // programの更新
      // 更新前のデータと比較して処理をする
      // または
      // 全削除して作り直す(★ 基本簡単な方で。開発スピードを重視)

      // 古いのを削除
      for (const programPiece of concert.program.items) {
        await API.graphql(
          graphqlOperation(deleteProgramPiece, {
            input: { id: programPiece.id },
          })
        );
      }

      // 新しく作成
      for (let i = 0; i < program.length; i++) {
        const programPieceInput = {
          pieceId: program[i].pieceId,
          concertId: concert.id,
          order: i + 1,
        };
        await API.graphql(
          graphqlOperation(createProgramPiece, { input: programPieceInput })
        );
      }

      history.push(paths.viewConcert(id));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <h1>Edit Concert</h1>

      <QueryResultHandler {...queryResult}>
        <ConcertForm initialState={concert} onSubmit={handleSubmit} />
      </QueryResultHandler>
    </div>
  );
};

export default EditConcert;
