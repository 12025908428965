import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
import useComposerForm from "./useComposerForm";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const ComposerForm = ({ initialState, onSubmit }) => {
  const classes = useStyles();

  const {
    formState,
    handleChange,
    handleSubmit,
    isValidInput,
  } = useComposerForm({
    initialState,
    onSubmit,
  });

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <TextField
        label="名前"
        variant="outlined"
        fullWidth
        autoFocus
        value={formState.nameJa}
        onChange={handleChange("nameJa")}
      />
      <TextField
        label="長い名前"
        variant="outlined"
        fullWidth
        autoFocus
        value={formState.longNameJa}
        onChange={handleChange("longNameJa")}
      />
      <TextField
        label="名前（ラテン文字表記）"
        variant="outlined"
        fullWidth
        value={formState.nameLatin}
        onChange={handleChange("nameLatin")}
      />
      <TextField
        label="長い名前（ラテン文字表記）"
        variant="outlined"
        fullWidth
        value={formState.longNameLatin}
        onChange={handleChange("longNameLatin")}
      />
      <Button
        variant="contained"
        color="primary"
        disabled={!isValidInput}
        type="submit"
      >
        登録
      </Button>
    </form>
  );
};

export default ComposerForm;
