import React from "react";

const ErrorNotification = ({ error }) => {
  return (
    <div>
      <h3>Error</h3>
      <p>{error}</p>
    </div>
  );
};

export default ErrorNotification;
