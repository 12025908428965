import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
import ComposerSelector from "admin/components/ComposerSelector";
import usePieceForm from "./usePieceForm";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const PieceForm = ({ initialState, onSubmit, lockComposer = false }) => {
  const classes = useStyles();

  const { formState, handleChange, handleSubmit, isValidInput } = usePieceForm({
    initialState,
    onSubmit,
  });

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <ComposerSelector
        label="作曲家"
        variant="outlined"
        fullWidth
        value={formState.composerId}
        onChange={handleChange("composerId")}
        disabled={lockComposer}
      />
      <TextField
        label="曲名"
        variant="outlined"
        fullWidth
        value={formState.nameJa}
        onChange={handleChange("nameJa")}
      />
      <TextField
        label="長い曲名"
        variant="outlined"
        fullWidth
        value={formState.longNameJa}
        onChange={handleChange("longNameJa")}
      />
      <TextField
        label="曲名（英語表記）"
        variant="outlined"
        fullWidth
        value={formState.nameEn}
        onChange={handleChange("nameEn")}
      />
      <TextField
        label="長い曲名（英語表記）"
        variant="outlined"
        fullWidth
        value={formState.longNameEn}
        onChange={handleChange("longNameEn")}
      />
      <Button
        variant="contained"
        color="primary"
        disabled={!isValidInput}
        type="submit"
      >
        登録
      </Button>
    </form>
  );
};

export default PieceForm;
