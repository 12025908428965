const ADMIN_PREFIX = "/__admin";

const paths = {
  adminRoot: () => `${ADMIN_PREFIX}/`,
  listComposers: () => `${ADMIN_PREFIX}/composers`,
  registerComposer: () => `${ADMIN_PREFIX}/composers/new`,
  viewComposer: (id = ":id") => `${ADMIN_PREFIX}/composers/${id}`,
  editComposer: (id = ":id") => `${ADMIN_PREFIX}/composers/${id}/edit`,
  listPieces: () => `${ADMIN_PREFIX}/pieces`,
  registerPiece: () => `${ADMIN_PREFIX}/pieces/new`,
  viewPiece: (id = ":id") => `${ADMIN_PREFIX}/pieces/${id}`,
  editPiece: (id = ":id") => `${ADMIN_PREFIX}/pieces/${id}/edit`,
  listOrchestras: () => `${ADMIN_PREFIX}/orchestras`,
  registerOrchestra: () => `${ADMIN_PREFIX}/orchestras/new`,
  viewOrchestra: (id = ":id") => `${ADMIN_PREFIX}/orchestras/${id}`,
  editOrchestra: (id = ":id") => `${ADMIN_PREFIX}/orchestras/${id}/edit`,
  listConcerts: () => `${ADMIN_PREFIX}/concerts`,
  registerConcert: () => `${ADMIN_PREFIX}/concerts/new`,
  viewConcert: (id = ":id") => `${ADMIN_PREFIX}/concerts/${id}`,
  editConcert: (id = ":id") => `${ADMIN_PREFIX}/concerts/${id}/edit`,
  batchJobs: () => `${ADMIN_PREFIX}/batch-jobs`,
};

export default paths;
