const map = {
  NHK交響楽団: "N響",
  読売日本交響楽団: "読響",
  東京フィルハーモニー交響楽団: "東フィル",
  東京都交響楽団: "都響",
};

export function getOrchestraAbbreviatedName(name) {
  return map[name] || name;
}
