import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import usePieces from "hooks/usePieces";
import QueryResultHandler from "components/QueryResultHandler";
import paths from "admin/paths";

const Pieces = ({ pieces }) => {
  return (
    <ul>
      {pieces.map((piece) => (
        <li key={piece.id}>
          <Link to={paths.viewPiece(piece.id)}>
            {piece.nameJa} ({piece.nameEn}) - {piece.composer.nameJa}
          </Link>
        </li>
      ))}
    </ul>
  );
};

const ListPieces = () => {
  const { pieces, ...queryResult } = usePieces();

  return (
    <div>
      <h1>Pieces</h1>
      <Button
        variant="outlined"
        color="primary"
        component={Link}
        to={paths.registerPiece()}
      >
        New
      </Button>
      <QueryResultHandler {...queryResult}>
        <Pieces pieces={pieces} />
      </QueryResultHandler>
    </div>
  );
};

export default ListPieces;
