import { listPieces } from "graphql/queries";
import useQuery from "./useQuery";

const usePieces = () => {
  const { data, errors, loading } = useQuery(listPieces, { limit: 1000 });

  const pieces = data?.listPieces?.items ?? [];

  pieces.sort((a, b) => {
    if (a.composer.id !== b.composer.id) {
      return a.composer.nameLatin.localeCompare(b.composer.nameLatin);
    }
    return a.nameJa.localeCompare(b.nameJa);
  });

  return { pieces, errors, loading };
};

export default usePieces;
