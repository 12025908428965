/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const createComposer = /* GraphQL */ `
  mutation CreateComposer(
    $input: CreateComposerInput!
    $condition: ModelComposerConditionInput
  ) {
    createComposer(input: $input, condition: $condition) {
      id
      nameJa
      longNameJa
      nameLatin
      longNameLatin
      pieces {
        items {
          id
          nameJa
          longNameJa
          nameEn
          longNameEn
          composerId
          composer {
            id
            nameJa
            longNameJa
            nameLatin
            longNameLatin
            pieces {
              nextToken
            }
            createdAt
            updatedAt
          }
          concerts {
            items {
              id
              concertId
              pieceId
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateComposer = /* GraphQL */ `
  mutation UpdateComposer(
    $input: UpdateComposerInput!
    $condition: ModelComposerConditionInput
  ) {
    updateComposer(input: $input, condition: $condition) {
      id
      nameJa
      longNameJa
      nameLatin
      longNameLatin
      pieces {
        items {
          id
          nameJa
          longNameJa
          nameEn
          longNameEn
          composerId
          composer {
            id
            nameJa
            longNameJa
            nameLatin
            longNameLatin
            pieces {
              nextToken
            }
            createdAt
            updatedAt
          }
          concerts {
            items {
              id
              concertId
              pieceId
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteComposer = /* GraphQL */ `
  mutation DeleteComposer(
    $input: DeleteComposerInput!
    $condition: ModelComposerConditionInput
  ) {
    deleteComposer(input: $input, condition: $condition) {
      id
      nameJa
      longNameJa
      nameLatin
      longNameLatin
      pieces {
        items {
          id
          nameJa
          longNameJa
          nameEn
          longNameEn
          composerId
          composer {
            id
            nameJa
            longNameJa
            nameLatin
            longNameLatin
            pieces {
              nextToken
            }
            createdAt
            updatedAt
          }
          concerts {
            items {
              id
              concertId
              pieceId
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPiece = /* GraphQL */ `
  mutation CreatePiece(
    $input: CreatePieceInput!
    $condition: ModelPieceConditionInput
  ) {
    createPiece(input: $input, condition: $condition) {
      id
      nameJa
      longNameJa
      nameEn
      longNameEn
      composerId
      composer {
        id
        nameJa
        longNameJa
        nameLatin
        longNameLatin
        pieces {
          items {
            id
            nameJa
            longNameJa
            nameEn
            longNameEn
            composerId
            composer {
              id
              nameJa
              longNameJa
              nameLatin
              longNameLatin
              createdAt
              updatedAt
            }
            concerts {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      concerts {
        items {
          id
          concertId
          pieceId
          concert {
            id
            orchestraId
            orchestra {
              id
              nameJa
              nameEn
              website
              createdAt
              updatedAt
            }
            nameJa
            nameEn
            program {
              nextToken
            }
            date
            venue
            venuePrefecture
            website
            note
            conductor
            createdAt
            updatedAt
          }
          piece {
            id
            nameJa
            longNameJa
            nameEn
            longNameEn
            composerId
            composer {
              id
              nameJa
              longNameJa
              nameLatin
              longNameLatin
              createdAt
              updatedAt
            }
            concerts {
              nextToken
            }
            createdAt
            updatedAt
          }
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePiece = /* GraphQL */ `
  mutation UpdatePiece(
    $input: UpdatePieceInput!
    $condition: ModelPieceConditionInput
  ) {
    updatePiece(input: $input, condition: $condition) {
      id
      nameJa
      longNameJa
      nameEn
      longNameEn
      composerId
      composer {
        id
        nameJa
        longNameJa
        nameLatin
        longNameLatin
        pieces {
          items {
            id
            nameJa
            longNameJa
            nameEn
            longNameEn
            composerId
            composer {
              id
              nameJa
              longNameJa
              nameLatin
              longNameLatin
              createdAt
              updatedAt
            }
            concerts {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      concerts {
        items {
          id
          concertId
          pieceId
          concert {
            id
            orchestraId
            orchestra {
              id
              nameJa
              nameEn
              website
              createdAt
              updatedAt
            }
            nameJa
            nameEn
            program {
              nextToken
            }
            date
            venue
            venuePrefecture
            website
            note
            conductor
            createdAt
            updatedAt
          }
          piece {
            id
            nameJa
            longNameJa
            nameEn
            longNameEn
            composerId
            composer {
              id
              nameJa
              longNameJa
              nameLatin
              longNameLatin
              createdAt
              updatedAt
            }
            concerts {
              nextToken
            }
            createdAt
            updatedAt
          }
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePiece = /* GraphQL */ `
  mutation DeletePiece(
    $input: DeletePieceInput!
    $condition: ModelPieceConditionInput
  ) {
    deletePiece(input: $input, condition: $condition) {
      id
      nameJa
      longNameJa
      nameEn
      longNameEn
      composerId
      composer {
        id
        nameJa
        longNameJa
        nameLatin
        longNameLatin
        pieces {
          items {
            id
            nameJa
            longNameJa
            nameEn
            longNameEn
            composerId
            composer {
              id
              nameJa
              longNameJa
              nameLatin
              longNameLatin
              createdAt
              updatedAt
            }
            concerts {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      concerts {
        items {
          id
          concertId
          pieceId
          concert {
            id
            orchestraId
            orchestra {
              id
              nameJa
              nameEn
              website
              createdAt
              updatedAt
            }
            nameJa
            nameEn
            program {
              nextToken
            }
            date
            venue
            venuePrefecture
            website
            note
            conductor
            createdAt
            updatedAt
          }
          piece {
            id
            nameJa
            longNameJa
            nameEn
            longNameEn
            composerId
            composer {
              id
              nameJa
              longNameJa
              nameLatin
              longNameLatin
              createdAt
              updatedAt
            }
            concerts {
              nextToken
            }
            createdAt
            updatedAt
          }
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOrchestra = /* GraphQL */ `
  mutation CreateOrchestra(
    $input: CreateOrchestraInput!
    $condition: ModelOrchestraConditionInput
  ) {
    createOrchestra(input: $input, condition: $condition) {
      id
      nameJa
      nameEn
      website
      concerts {
        items {
          id
          orchestraId
          orchestra {
            id
            nameJa
            nameEn
            website
            concerts {
              nextToken
            }
            createdAt
            updatedAt
          }
          nameJa
          nameEn
          program {
            items {
              id
              concertId
              pieceId
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          date
          venue
          venuePrefecture
          website
          note
          conductor
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOrchestra = /* GraphQL */ `
  mutation UpdateOrchestra(
    $input: UpdateOrchestraInput!
    $condition: ModelOrchestraConditionInput
  ) {
    updateOrchestra(input: $input, condition: $condition) {
      id
      nameJa
      nameEn
      website
      concerts {
        items {
          id
          orchestraId
          orchestra {
            id
            nameJa
            nameEn
            website
            concerts {
              nextToken
            }
            createdAt
            updatedAt
          }
          nameJa
          nameEn
          program {
            items {
              id
              concertId
              pieceId
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          date
          venue
          venuePrefecture
          website
          note
          conductor
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrchestra = /* GraphQL */ `
  mutation DeleteOrchestra(
    $input: DeleteOrchestraInput!
    $condition: ModelOrchestraConditionInput
  ) {
    deleteOrchestra(input: $input, condition: $condition) {
      id
      nameJa
      nameEn
      website
      concerts {
        items {
          id
          orchestraId
          orchestra {
            id
            nameJa
            nameEn
            website
            concerts {
              nextToken
            }
            createdAt
            updatedAt
          }
          nameJa
          nameEn
          program {
            items {
              id
              concertId
              pieceId
              order
              createdAt
              updatedAt
            }
            nextToken
          }
          date
          venue
          venuePrefecture
          website
          note
          conductor
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createConcert = /* GraphQL */ `
  mutation CreateConcert(
    $input: CreateConcertInput!
    $condition: ModelConcertConditionInput
  ) {
    createConcert(input: $input, condition: $condition) {
      id
      orchestraId
      orchestra {
        id
        nameJa
        nameEn
        website
        concerts {
          items {
            id
            orchestraId
            orchestra {
              id
              nameJa
              nameEn
              website
              createdAt
              updatedAt
            }
            nameJa
            nameEn
            program {
              nextToken
            }
            date
            venue
            venuePrefecture
            website
            note
            conductor
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nameJa
      nameEn
      program {
        items {
          id
          concertId
          pieceId
          concert {
            id
            orchestraId
            orchestra {
              id
              nameJa
              nameEn
              website
              createdAt
              updatedAt
            }
            nameJa
            nameEn
            program {
              nextToken
            }
            date
            venue
            venuePrefecture
            website
            note
            conductor
            createdAt
            updatedAt
          }
          piece {
            id
            nameJa
            longNameJa
            nameEn
            longNameEn
            composerId
            composer {
              id
              nameJa
              longNameJa
              nameLatin
              longNameLatin
              createdAt
              updatedAt
            }
            concerts {
              nextToken
            }
            createdAt
            updatedAt
          }
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      date
      venue
      venuePrefecture
      website
      note
      conductor
      createdAt
      updatedAt
    }
  }
`;
export const updateConcert = /* GraphQL */ `
  mutation UpdateConcert(
    $input: UpdateConcertInput!
    $condition: ModelConcertConditionInput
  ) {
    updateConcert(input: $input, condition: $condition) {
      id
      orchestraId
      orchestra {
        id
        nameJa
        nameEn
        website
        concerts {
          items {
            id
            orchestraId
            orchestra {
              id
              nameJa
              nameEn
              website
              createdAt
              updatedAt
            }
            nameJa
            nameEn
            program {
              nextToken
            }
            date
            venue
            venuePrefecture
            website
            note
            conductor
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nameJa
      nameEn
      program {
        items {
          id
          concertId
          pieceId
          concert {
            id
            orchestraId
            orchestra {
              id
              nameJa
              nameEn
              website
              createdAt
              updatedAt
            }
            nameJa
            nameEn
            program {
              nextToken
            }
            date
            venue
            venuePrefecture
            website
            note
            conductor
            createdAt
            updatedAt
          }
          piece {
            id
            nameJa
            longNameJa
            nameEn
            longNameEn
            composerId
            composer {
              id
              nameJa
              longNameJa
              nameLatin
              longNameLatin
              createdAt
              updatedAt
            }
            concerts {
              nextToken
            }
            createdAt
            updatedAt
          }
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      date
      venue
      venuePrefecture
      website
      note
      conductor
      createdAt
      updatedAt
    }
  }
`;
export const deleteConcert = /* GraphQL */ `
  mutation DeleteConcert(
    $input: DeleteConcertInput!
    $condition: ModelConcertConditionInput
  ) {
    deleteConcert(input: $input, condition: $condition) {
      id
      orchestraId
      orchestra {
        id
        nameJa
        nameEn
        website
        concerts {
          items {
            id
            orchestraId
            orchestra {
              id
              nameJa
              nameEn
              website
              createdAt
              updatedAt
            }
            nameJa
            nameEn
            program {
              nextToken
            }
            date
            venue
            venuePrefecture
            website
            note
            conductor
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nameJa
      nameEn
      program {
        items {
          id
          concertId
          pieceId
          concert {
            id
            orchestraId
            orchestra {
              id
              nameJa
              nameEn
              website
              createdAt
              updatedAt
            }
            nameJa
            nameEn
            program {
              nextToken
            }
            date
            venue
            venuePrefecture
            website
            note
            conductor
            createdAt
            updatedAt
          }
          piece {
            id
            nameJa
            longNameJa
            nameEn
            longNameEn
            composerId
            composer {
              id
              nameJa
              longNameJa
              nameLatin
              longNameLatin
              createdAt
              updatedAt
            }
            concerts {
              nextToken
            }
            createdAt
            updatedAt
          }
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      date
      venue
      venuePrefecture
      website
      note
      conductor
      createdAt
      updatedAt
    }
  }
`;
export const createProgramPiece = /* GraphQL */ `
  mutation CreateProgramPiece(
    $input: CreateProgramPieceInput!
    $condition: ModelProgramPieceConditionInput
  ) {
    createProgramPiece(input: $input, condition: $condition) {
      id
      concertId
      pieceId
      concert {
        id
        orchestraId
        orchestra {
          id
          nameJa
          nameEn
          website
          concerts {
            items {
              id
              orchestraId
              nameJa
              nameEn
              date
              venue
              venuePrefecture
              website
              note
              conductor
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nameJa
        nameEn
        program {
          items {
            id
            concertId
            pieceId
            concert {
              id
              orchestraId
              nameJa
              nameEn
              date
              venue
              venuePrefecture
              website
              note
              conductor
              createdAt
              updatedAt
            }
            piece {
              id
              nameJa
              longNameJa
              nameEn
              longNameEn
              composerId
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        date
        venue
        venuePrefecture
        website
        note
        conductor
        createdAt
        updatedAt
      }
      piece {
        id
        nameJa
        longNameJa
        nameEn
        longNameEn
        composerId
        composer {
          id
          nameJa
          longNameJa
          nameLatin
          longNameLatin
          pieces {
            items {
              id
              nameJa
              longNameJa
              nameEn
              longNameEn
              composerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        concerts {
          items {
            id
            concertId
            pieceId
            concert {
              id
              orchestraId
              nameJa
              nameEn
              date
              venue
              venuePrefecture
              website
              note
              conductor
              createdAt
              updatedAt
            }
            piece {
              id
              nameJa
              longNameJa
              nameEn
              longNameEn
              composerId
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateProgramPiece = /* GraphQL */ `
  mutation UpdateProgramPiece(
    $input: UpdateProgramPieceInput!
    $condition: ModelProgramPieceConditionInput
  ) {
    updateProgramPiece(input: $input, condition: $condition) {
      id
      concertId
      pieceId
      concert {
        id
        orchestraId
        orchestra {
          id
          nameJa
          nameEn
          website
          concerts {
            items {
              id
              orchestraId
              nameJa
              nameEn
              date
              venue
              venuePrefecture
              website
              note
              conductor
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nameJa
        nameEn
        program {
          items {
            id
            concertId
            pieceId
            concert {
              id
              orchestraId
              nameJa
              nameEn
              date
              venue
              venuePrefecture
              website
              note
              conductor
              createdAt
              updatedAt
            }
            piece {
              id
              nameJa
              longNameJa
              nameEn
              longNameEn
              composerId
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        date
        venue
        venuePrefecture
        website
        note
        conductor
        createdAt
        updatedAt
      }
      piece {
        id
        nameJa
        longNameJa
        nameEn
        longNameEn
        composerId
        composer {
          id
          nameJa
          longNameJa
          nameLatin
          longNameLatin
          pieces {
            items {
              id
              nameJa
              longNameJa
              nameEn
              longNameEn
              composerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        concerts {
          items {
            id
            concertId
            pieceId
            concert {
              id
              orchestraId
              nameJa
              nameEn
              date
              venue
              venuePrefecture
              website
              note
              conductor
              createdAt
              updatedAt
            }
            piece {
              id
              nameJa
              longNameJa
              nameEn
              longNameEn
              composerId
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteProgramPiece = /* GraphQL */ `
  mutation DeleteProgramPiece(
    $input: DeleteProgramPieceInput!
    $condition: ModelProgramPieceConditionInput
  ) {
    deleteProgramPiece(input: $input, condition: $condition) {
      id
      concertId
      pieceId
      concert {
        id
        orchestraId
        orchestra {
          id
          nameJa
          nameEn
          website
          concerts {
            items {
              id
              orchestraId
              nameJa
              nameEn
              date
              venue
              venuePrefecture
              website
              note
              conductor
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nameJa
        nameEn
        program {
          items {
            id
            concertId
            pieceId
            concert {
              id
              orchestraId
              nameJa
              nameEn
              date
              venue
              venuePrefecture
              website
              note
              conductor
              createdAt
              updatedAt
            }
            piece {
              id
              nameJa
              longNameJa
              nameEn
              longNameEn
              composerId
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        date
        venue
        venuePrefecture
        website
        note
        conductor
        createdAt
        updatedAt
      }
      piece {
        id
        nameJa
        longNameJa
        nameEn
        longNameEn
        composerId
        composer {
          id
          nameJa
          longNameJa
          nameLatin
          longNameLatin
          pieces {
            items {
              id
              nameJa
              longNameJa
              nameEn
              longNameEn
              composerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        concerts {
          items {
            id
            concertId
            pieceId
            concert {
              id
              orchestraId
              nameJa
              nameEn
              date
              venue
              venuePrefecture
              website
              note
              conductor
              createdAt
              updatedAt
            }
            piece {
              id
              nameJa
              longNameJa
              nameEn
              longNameEn
              composerId
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      order
      createdAt
      updatedAt
    }
  }
`;
