import { API, graphqlOperation } from "aws-amplify";
import { listComposers, listPieces } from "graphql/queries";
import { deleteComposer, deletePiece } from "graphql/mutations";

export const deleteAllComposersAndPieces = async () => {
  try {
    console.log("Started deleting composers and pieces");

    let result;

    // delete pieces
    result = await API.graphql(graphqlOperation(listPieces));
    if (result.errors) throw result.errors;
    const pieces = result.data.listPieces.items;
    for (const piece of pieces) {
      await API.graphql(
        graphqlOperation(deletePiece, { input: { id: piece.id } })
      );
    }

    // delete composers
    result = await API.graphql(graphqlOperation(listComposers));
    if (result.errors) throw result.errors;
    const composers = result.data.listComposers.items;
    for (const composer of composers) {
      await API.graphql(
        graphqlOperation(deleteComposer, { input: { id: composer.id } })
      );
    }
    console.log("Finished deleting composers and pieces");
  } catch (err) {
    console.log(err);
  }
};

export default deleteAllComposersAndPieces;
