import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { API, graphqlOperation } from "aws-amplify";
import { deletePiece } from "graphql/mutations";
import usePiece from "hooks/usePiece";
import QueryResultHandler from "components/QueryResultHandler";
import ConfirmationButton from "components/ConfirmationButton";
import paths from "admin/paths";

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const Piece = ({ piece }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleDelete = async () => {
    const input = { id: piece.id };
    try {
      await API.graphql(graphqlOperation(deletePiece, { input }));
      history.push(paths.viewComposer(piece.composer.id));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <h1>{piece.nameJa}</h1>
      <h5>{piece.longNameJa}</h5>
      <h3>{piece.nameEn}</h3>
      <h5>{piece.longNameEn}</h5>
      <p>
        作曲：{" "}
        <Link to={paths.viewComposer(piece.composer.id)}>
          {piece.composer.nameJa}
        </Link>
      </p>
      <div className={classes.actionButtons}>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to={paths.editPiece(piece.id)}
        >
          Edit
        </Button>
        <ConfirmationButton
          variant="outlined"
          color="secondary"
          onOk={handleDelete}
          title="曲の削除"
          message={`『${piece.nameJa}』を削除します。`}
        >
          Delete
        </ConfirmationButton>
      </div>
    </div>
  );
};

const ViewPiece = () => {
  const { id } = useParams();
  const { piece, ...queryResult } = usePiece(id);

  return (
    <QueryResultHandler {...queryResult}>
      <Piece piece={piece} />
    </QueryResultHandler>
  );
};

export default ViewPiece;
