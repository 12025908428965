import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    fontSize: "0.85rem",
  },
  linkItem: {
    marginRight: theme.spacing(2),
  },
  copyright: {
    textAlign: "center",
  },
}));

export const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <div>
        <span className={classes.linkItem}>
          <Link to="/">Top</Link>
        </span>
        <span className={classes.linkItem}>
          <Link to="/privacy-policy">プライバシーポリシー</Link>
        </span>
        <span>
          <Link to="/disclaimer">本サイトのご利用条件・免責事項</Link>
        </span>
      </div>
      <div className={classes.copyright}>
        Copyright &copy; 2021 concert-jp.com
      </div>
    </footer>
  );
};
