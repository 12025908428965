import { API, graphqlOperation } from "aws-amplify";
import { listComposers } from "graphql/queries";
import {
  createComposer,
  updateComposer,
  createPiece,
  updatePiece,
} from "graphql/mutations";
import executeMutation from "./execute-mutation";

// 仕様
// idがないデータは新規として登録
// ただし、名前が同じデータがあったらスキップ
// idがあるものは更新処理
// すでに登録されているデータはインポートデータに存在しなくても削除はしない

const findById = (existingItems, item) => {
  return existingItems.find((existingItem) => existingItem.id === item.id);
};

const findByNameJa = (existingItems, item) => {
  return existingItems.find(
    (existingItem) => existingItem.nameJa === item.nameJa
  );
};

const hasComposerSameValues = (a, b) => {
  return (
    a.id === b.id &&
    a.nameJa === b.nameJa &&
    a.longNameJa === b.longNameJa &&
    a.nameLatin === b.nameLatin &&
    a.longNameLatin === b.longNameLatin
  );
};

const hasPieceSameValues = (a, b) => {
  return (
    a.id === b.id &&
    a.nameJa === b.nameJa &&
    a.longNameJa === b.longNameJa &&
    a.nameEn === b.nameEn &&
    a.longNameEn === b.longNameEn
  );
};

const executeCreateComposer = async (composer) => {
  const input = {
    id: composer.id || undefined,
    nameJa: composer.nameJa,
    longNameJa: composer.longNameJa || "",
    nameLatin: composer.nameLatin || "",
    longNameLatin: composer.longNameLatin || "",
  };
  return await executeMutation(createComposer, input, "createComposer");
};

const executeUpdateComposer = async (composer) => {
  const input = {
    id: composer.id,
    nameJa: composer.nameJa,
    longNameJa: composer.longNameJa || "",
    nameLatin: composer.nameLatin || "",
    longNameLatin: composer.longNameLatin || "",
  };
  return await executeMutation(updateComposer, input, "updateComposer");
};

const executeCreatePiece = async (composerId, piece) => {
  const input = {
    id: piece.id || undefined,
    composerId,
    nameJa: piece.nameJa,
    longNameJa: piece.longNameJa || "",
    nameEn: piece.nameEn || "",
    longNameEn: piece.longNameEn || "",
  };
  return await executeMutation(createPiece, input, "createPiece");
};

const executeUpdatePiece = async (piece) => {
  const input = {
    id: piece.id,
    nameJa: piece.nameJa,
    longNameJa: piece.longNameJa || "",
    nameEn: piece.nameEn || "",
    longNameEn: piece.longNameEn || "",
  };
  return await executeMutation(updatePiece, input, "updatePiece");
};

export const importComposersAndPieces = async (data) => {
  console.log(data);

  const report = {
    composers: { create: 0, update: 0, skip: 0, skipDueToNameConflict: 0 },
    pieces: { create: 0, update: 0, skip: 0, skipDueToNameConflict: 0 },
    errors: [],
  };

  try {
    console.log("Started registering composers and pieces");

    const result = await API.graphql(graphqlOperation(listComposers));
    if (result.errors) throw result.errors;
    const existingComposers = result.data.listComposers.items;

    for (const composer of data) {
      if (!composer.nameJa) continue; // Placeholderとして空のレコードがある

      const existingItem = findById(existingComposers, composer);
      let composerId; // pieceを作るために必要

      if (!existingItem) {
        // 存在しない -> create
        if (findByNameJa(existingComposers, composer) != null) {
          // ただし、同名のデータが存在する場合はスキップ
          report.composers.skipDueToNameConflict++;
        } else {
          const { data, errors } = await executeCreateComposer(composer);
          if (errors) {
            report.errors.push(errors);
          } else {
            report.composers.create++;
          }
          composerId = data.createComposer.id;
        }
      } else if (!hasComposerSameValues(composer, existingItem)) {
        // 存在するが値が変わってる -> update
        const { errors } = await executeUpdateComposer(composer);
        if (errors) {
          report.errors.push(errors);
        } else {
          report.composers.update++;
        }
        composerId = composer.id;
      } else {
        // 同じデータが存在する -> 何もしない
        report.composers.skip++;
        composerId = composer.id;
      }

      // Pieces

      const existingPieces = existingItem ? existingItem.pieces.items : [];

      if (composerId && composer.pieces) {
        for (const piece of composer.pieces) {
          if (!piece.nameJa) continue; // Placeholderとして空のレコードがある

          const existingPieceItem = findById(existingPieces, piece);
          if (!existingPieceItem) {
            // 存在しない -> create
            if (findByNameJa(existingPieces, piece) != null) {
              // ただし、同名のデータが存在する場合はスキップ
              report.pieces.skipDueToNameConflict++;
            } else {
              const { errors } = await executeCreatePiece(composerId, piece);
              if (errors) {
                report.errors.push(errors);
              } else {
                report.pieces.create++;
              }
            }
          } else if (!hasPieceSameValues(piece, existingPieceItem)) {
            // 存在するが値が変わってる -> update
            const { errors } = await executeUpdatePiece(piece);
            if (errors) {
              report.errors.push(errors);
            } else {
              report.pieces.update++;
            }
          } else {
            // 同じデータが存在する -> 何もしない
            report.pieces.skip++;
          }
        }
      }
    }
    console.log("Finished registering composers and pieces");
    console.log(report);
  } catch (err) {
    console.log(err);
  }
};

export default importComposersAndPieces;
