import { getOrchestra } from "graphql/queries";
import useQuery from "./useQuery";

const useOrchestra = (id) => {
  const { data, errors, loading } = useQuery(getOrchestra, { id });

  const orchestra = data?.getOrchestra ?? null;

  return { orchestra, errors, loading };
};

export default useOrchestra;
