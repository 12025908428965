import { useState } from "react";

const useConcertForm = ({ initialState, onSubmit }) => {
  const [formState, setFormState] = useState({
    orchestraId: initialState?.orchestraId || "",
    nameJa: initialState?.nameJa || "",
    nameEn: initialState?.nameEn || "",
    date: initialState?.date || null,
    venue: initialState?.venue || "",
    venuePrefecture: initialState?.venuePrefecture || "13", // 東京をデフォルトに
    website: initialState?.website || "",
    conductor: initialState?.conductor || "",
    note: initialState?.note || "",
    program: initialState?.program
      ? initialState.program.items.map(({ pieceId }) => ({ pieceId }))
      : [],
  });

  // formState.program の shape
  // [ { pieceId: "ab012xxxxxx"}, ... ]

  // PROBLEM
  // editのとき、programをそのまま流しているので
  // [ { id: "xxxx", concertId: "xxxx", pieceId: "xxxx" ...etc }, ... ]
  // となってしまっている
  // pieceIdも含まれてはいるが、現在何かが原因でちゃんと動いていない。

  // →
  // ProgramPanelのhandleItemRemove で
  // すでにidを持っているときには削除マークをつけるような実装を
  // しようとしていて、まだしていなかったことが原因
  // 全削除で作り直す方針なら、難しいことはいらなくて、
  // ここのuseStateのinitializeでpieceId以外のプロパティを消してしまえばいい

  // →
  // などの検討の結果、このフォームはごく単純に最終的なpieceIdの並びだけを保持する
  // 仕組みにすることにした。
  // 更新処理の際は一旦古いprogram pieceを全部削除して作り直すことを想定している。

  const handleChange = (key) => (arg) => {
    if (arg.target != null && arg.target.value != null) {
      setFormState((state) => ({ ...state, [key]: arg.target.value }));
    } else {
      setFormState((state) => ({ ...state, [key]: arg }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onSubmit(formState);
  };

  const isValidInput =
    formState.orchestraId &&
    formState.nameJa &&
    formState.date &&
    formState.venue &&
    formState.venuePrefecture;

  return { formState, handleChange, handleSubmit, isValidInput };
};

export default useConcertForm;
