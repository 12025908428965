import { useParams, useHistory } from "react-router-dom";
import { startOfMonth, format } from "date-fns";
import ConcertCalendar from "components/ConcertCalendar";

const ConcertCalendarPage = () => {
  const history = useHistory();
  const { month: monthParam } = useParams();
  const month = getMonth(monthParam);

  const handleMonthChange = (month) => {
    history.push(`/${format(month, "yyyy-MM")}`);
  };

  if (month == null) {
    return <div>Not found</div>;
  }

  return (
    <div>
      <h1>コンサート情報</h1>
      <ConcertCalendar month={month} onMonthChange={handleMonthChange} />
    </div>
  );
};

export default ConcertCalendarPage;

function getMonth(monthParam) {
  // 指定されていない場合は当月
  if (!monthParam) {
    return startOfMonth(new Date());
  }

  // monthParam は yyyy-MM の想定
  const result = /^(20\d\d)-(0[1-9]|1[012])$/.exec(monthParam);
  if (result) {
    const [, y, m] = result;
    const year = parseInt(y, 10);
    const month = parseInt(m, 10);
    return new Date(year, month - 1, 1);
  } else {
    // bad request
    return null;
  }
}
