import { useState } from "react";

const usePieceForm = ({ initialState, onSubmit }) => {
  const [formState, setFormState] = useState({
    composerId: initialState?.composerId || "",
    nameJa: initialState?.nameJa || "",
    longNameJa: initialState?.longNameJa || "",
    nameEn: initialState?.nameEn || "",
    longNameEn: initialState?.longNameEn || "",
  });

  const handleChange = (key) => (e) => {
    setFormState((state) => ({ ...state, [key]: e.target?.value ?? e }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onSubmit(formState);
  };

  const isValidInput = formState.composerId && formState.nameJa;

  return { formState, handleChange, handleSubmit, isValidInput };
};

export default usePieceForm;
