import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import PieceSelectorDialog from "./PieceSelectorDialog";
import findPiece from "./utils/findPiece";
import findPieceComposer from "./utils/findPieceComposer";

const PieceSelectorField = ({ composers, value, onChange, ...props }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (value) => {
    setOpen(false);
    if (onChange) onChange(value);
  };

  // propとして受け取るvalueはpieceId
  const piece = findPiece(value, composers);
  const composer = findPieceComposer(value, composers);
  const displayValue = piece ? `${composer.nameJa} - ${piece.longNameJa}` : "-";

  return (
    <>
      <TextField {...props} value={displayValue} onClick={handleClick} />
      <PieceSelectorDialog
        composers={composers}
        open={open}
        onClose={handleClose}
        value={value}
        onChange={handleChange}
      />
    </>
  );
};

export default PieceSelectorField;
