import React from "react";

const AdminTop = () => {
  return (
    <div>
      <h1>Admin Dashboard</h1>
    </div>
  );
};

export default AdminTop;
