import { MonthSelector } from "components/MonthSelector";
import MonthlyCalendar from "./MonthlyCalendar";

const Calendar = ({ targetMonth, contents, onMonthChange }) => {
  return (
    <div>
      <MonthSelector value={targetMonth} onChange={onMonthChange} />
      <MonthlyCalendar targetMonth={targetMonth} contents={contents} />
    </div>
  );
};

export default Calendar;
