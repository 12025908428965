import React from "react";
import ProgramPiece from "./ProgramPiece";
import useComposers from "hooks/useComposers";

const ProgramPanel = ({ value, onChange }) => {
  // ProgramPieceの中で毎回クエリを発光しないように必要なデータをここで読み込む
  const { composers, errors, loading } = useComposers();
  if (errors) return null;
  if (loading) return <div>Loading...</div>;

  const handleItemChange = (index) => (pieceId) => {
    if (index === -1) {
      // new
      onChange([...value, { pieceId }]);
    } else {
      // update
      onChange([
        ...value.slice(0, index),
        { ...value[index], pieceId },
        ...value.slice(index + 1),
      ]);
    }
  };
  const handleOrderChange = (index, direction) => () => {
    const arr = [...value];
    const tmp = arr[index];
    arr[index] = arr[index + direction];
    arr[index + direction] = tmp;
    onChange(arr);
  };
  const handleItemRemove = (index) => () => {
    onChange([...value.slice(0, index), ...value.slice(index + 1)]);
  };

  return (
    <div>
      {value.map((programPiece, index) => (
        <ProgramPiece
          key={index}
          composers={composers}
          value={programPiece.pieceId}
          onChange={handleItemChange(index)}
          onUp={handleOrderChange(index, -1)}
          onDown={handleOrderChange(index, 1)}
          onRemove={handleItemRemove(index)}
          disableUp={index === 0}
          disableDown={index === value.length - 1}
        />
      ))}
      <ProgramPiece
        composers={composers}
        value=""
        onChange={handleItemChange(-1)}
        disableUp
        disableDown
        disableRemove
      />
    </div>
  );
};

export default ProgramPanel;
