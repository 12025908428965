import { Box, Chip } from "@material-ui/core";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { getLabelFromCode } from "utils/prefectures";
import { getOrchestraAbbreviatedName } from "utils/getOrchestraAbbreviatedName";

const ConcertItem = ({ concert }) => {
  return (
    <Box fontSize="0.8em" mt={1} mb={2}>
      <Box display="flex" flexWrap="wrap" alignItems="center">
        <Box fontWeight={700} mr={1} mb={0.5}>
          {format(new Date(concert.date), "H:mm")}
        </Box>
        <Box mr={1} mb={0.5}>
          ({getLabelFromCode(concert.venuePrefecture)})
        </Box>
        <Box mr={1} mb={0.5}>
          <Chip
            label={getOrchestraAbbreviatedName(concert.orchestra.nameJa)}
            size="small"
          />
        </Box>
      </Box>
      <Box>
        <Box fontSize="0.8em">{concert.venue}</Box>
        {concert.conductor && <Box fontSize="0.8em">{concert.conductor}</Box>}
        <Link to={`/concerts/${concert.id}`}>{concert.nameJa}</Link>
      </Box>
    </Box>
  );
};

export default ConcertItem;
