import { useState, useEffect } from "react";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Container } from "@material-ui/core";
import AdminNav from "./components/AdminNav";
import Routes from "./Routes";

const AdminPage = () => {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    }, []);
  });

  if (authState === AuthState.SignedIn && user) {
    return (
      <div>
        <AdminNav />
        <Container>
          <Routes />
        </Container>
      </div>
    );
  } else {
    return (
      <AmplifyAuthenticator>
        <AmplifySignIn slot="sign-in" hideSignUp />
      </AmplifyAuthenticator>
    );
  }
};

export default AdminPage;
