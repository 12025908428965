/**
 * composers一覧からpieceを探す
 * @param {*} pieceId
 * @param {*} composers
 */
const findPiece = (pieceId, composers) => {
  if (!pieceId) return null;

  for (const composer of composers) {
    for (const piece of composer.pieces.items) {
      if (piece.id === pieceId) {
        return piece;
      }
    }
  }
  return null;
};

export default findPiece;
