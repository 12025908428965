import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const ComposerSelectorField = ({
  composers,
  value,
  onChange,
  disabled,
  ...props
}) => {
  const handleChange = (_e, composer) => {
    if (onChange) onChange(composer.id);
  };

  return (
    <Autocomplete
      value={composers.find((composer) => composer.id === value) ?? null}
      onChange={handleChange}
      options={composers}
      disabled={disabled}
      getOptionLabel={(composer) =>
        `${composer.nameJa} / ${composer.nameLatin}`
      }
      renderInput={(params) => <TextField {...props} {...params} />}
    />
  );
};

export default ComposerSelectorField;
