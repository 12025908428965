import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import useOrchestras from "hooks/useOrchestras";
import QueryResultHandler from "components/QueryResultHandler";
import paths from "admin/paths";

const Orchestras = ({ orchestras }) => {
  return (
    <ul>
      {orchestras.map((orchestra) => (
        <li key={orchestra.id}>
          <Link to={paths.viewOrchestra(orchestra.id)}>{orchestra.nameJa}</Link>
        </li>
      ))}
    </ul>
  );
};

const ListOrchestras = () => {
  const { orchestras, ...queryResult } = useOrchestras();

  return (
    <div>
      <h1>Orchestras</h1>
      <Button
        variant="outlined"
        color="primary"
        component={Link}
        to={paths.registerOrchestra()}
      >
        New
      </Button>
      <QueryResultHandler {...queryResult}>
        <Orchestras orchestras={orchestras} />
      </QueryResultHandler>
    </div>
  );
};

export default ListOrchestras;
