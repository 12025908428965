/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-northeast-1",
  aws_cognito_identity_pool_id:
    "ap-northeast-1:afeb7831-aef0-4c72-82ff-1d1361b374bf",
  aws_cognito_region: "ap-northeast-1",
  aws_user_pools_id: "ap-northeast-1_4jk8Sun5I",
  aws_user_pools_web_client_id: "2ec6u619i15c6pnf0dd2u491mo",
  oauth: {},
  aws_appsync_graphqlEndpoint:
    "https://atxqp2ssavgptgnr3pujzjfovq.appsync-api.ap-northeast-1.amazonaws.com/graphql",
  aws_appsync_region: "ap-northeast-1",
  aws_appsync_authenticationType: "AWS_IAM",
};

export default awsmobile;
