import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import useComposers from "hooks/useComposers";
import QueryResultHandler from "components/QueryResultHandler";
import paths from "admin/paths";

const Composers = ({ composers }) => {
  return (
    <ul>
      {composers.map((composer) => (
        <li key={composer.id}>
          <Link to={paths.viewComposer(composer.id)}>
            {composer.nameJa} ({composer.nameLatin})
          </Link>
        </li>
      ))}
    </ul>
  );
};

const ListComposers = () => {
  const { composers, ...queryResult } = useComposers();

  return (
    <div>
      <h1>Composers</h1>
      <Button
        variant="outlined"
        color="primary"
        component={Link}
        to={paths.registerComposer()}
      >
        New
      </Button>
      <QueryResultHandler {...queryResult}>
        <Composers composers={composers} />
      </QueryResultHandler>
    </div>
  );
};

export default ListComposers;
