export const downloadFile = (data, fileName) => {
  try {
    const blob = new Blob([data], { type: "application/yaml" });
    const url = window.URL.createObjectURL(blob);

    var link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (err) {
    console.error(err);
  }
};

export default downloadFile;
