import YamlLoader from "admin/components/YamlLoader";
import ConfirmationButton from "components/ConfirmationButton";
import {
  deleteAllComposersAndPieces,
  importComposersAndPieces,
  exportComposersAndPieces,
} from "./batch-operations";

const ComposerBatchJobs = () => {
  const handleDeleteComposers = async () => {
    await deleteAllComposersAndPieces();
  };

  const handleImportComposers = async (data) => {
    await importComposersAndPieces(data);
  };

  const handleExportComposers = async () => {
    await exportComposersAndPieces();
  };

  return (
    <div>
      <h3>Delete All Composers and Pieces</h3>
      <p>先にコンサート情報を削除してください。</p>
      <ConfirmationButton
        message="すべての作曲家と曲を削除します。"
        onOk={handleDeleteComposers}
        variant="contained"
      >
        Delete
      </ConfirmationButton>

      <h3>Import Composers and Pieces</h3>
      <YamlLoader onLoad={handleImportComposers} />

      <h3>Export Composers and Pieces</h3>
      <ConfirmationButton
        message="作曲家と曲のデータをエクスポートします。"
        onOk={handleExportComposers}
        variant="contained"
      >
        Export
      </ConfirmationButton>
    </div>
  );
};

export default ComposerBatchJobs;
