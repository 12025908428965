import { listComposers } from "graphql/queries";
import useQuery from "./useQuery";

const useComposers = () => {
  const { data, errors, loading } = useQuery(listComposers, { limit: 1000 });

  const composers = data?.listComposers?.items ?? [];

  composers.sort((a, b) => a.nameLatin.localeCompare(b.nameLatin));

  composers.forEach((composer) => {
    composer.pieces.items.sort((a, b) => a.nameJa.localeCompare(b.nameJa));
  });

  return { composers, errors, loading };
};

export default useComposers;
