import { MonthSelector } from "components/MonthSelector";
import { MonthlyCalendar } from "./MonthlyCalendar";

export const MobileCalendar = ({ targetMonth, contents, onMonthChange }) => {
  return (
    <div>
      <MonthSelector value={targetMonth} onChange={onMonthChange} />
      <MonthlyCalendar targetMonth={targetMonth} contents={contents} />
      <MonthSelector value={targetMonth} onChange={onMonthChange} />
    </div>
  );
};
