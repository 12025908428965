import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import actHack from "./actHack";

const useQuery = (query, variables) => {
  const [data, setData] = useState(undefined);
  const [errors, setErrors] = useState(undefined);
  const [loading, setLoading] = useState(true);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await API.graphql(graphqlOperation(query, variables));

        if (results.errors) {
          // GraphQL Errors
          actHack(() => setErrors(results.errors));
        }
        actHack(() => setData(results.data));
      } catch (err) {
        if (err.errors) {
          actHack(() => setErrors(err.errors));
        } else {
          actHack(() => setErrors([err]));
        }
      } finally {
        actHack(() => setLoading(false));
      }
    };

    fetchData();
  }, [query, JSON.stringify(variables)]);

  return { data, errors, loading };
};

export default useQuery;
