import React from "react";
// import { Auth } from "aws-amplify";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  MuiThemeProvider,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { GAWrapper } from "components/GAWrapper";
import { Layout } from "components/Layout";
import AdminTopPage from "./admin";
import ConcertCalendarPage from "./pages/ConcertCalendarPage";
import ConcertPage from "./pages/ConcertPage";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicyPage";
import { DisclaimerPage } from "./pages/DisclaimerPage";

// Auth.currentCredentials()
//   .then((d) => console.log("data: ", d))
//   .catch((e) => console.log("error: ", e));

const theme = createMuiTheme({});

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <GAWrapper>
            <Layout>
              <Switch>
                <Route path="/__admin">
                  <AdminTopPage />
                </Route>
                <Route path="/concerts/:id" exact>
                  <ConcertPage />
                </Route>
                <Route path="/privacy-policy" exact>
                  <PrivacyPolicyPage />
                </Route>
                <Route path="/disclaimer" exact>
                  <DisclaimerPage />
                </Route>
                <Route path="/:month" exact>
                  <ConcertCalendarPage />
                </Route>
                <Route path="/">
                  <ConcertCalendarPage />
                </Route>
              </Switch>
            </Layout>
          </GAWrapper>
        </Router>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default App;
