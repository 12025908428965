import { API, graphqlOperation } from "aws-amplify";
import { listOrchestras } from "graphql/queries";
import { deleteOrchestra } from "graphql/mutations";

export const deleteAllOrchestras = async () => {
  try {
    console.log("Started deleting orchestras");

    const result = await API.graphql(graphqlOperation(listOrchestras));
    if (result.errors) throw result.errors;
    const orchestras = result.data.listOrchestras.items;
    for (const orchestra of orchestras) {
      await API.graphql(
        graphqlOperation(deleteOrchestra, { input: { id: orchestra.id } })
      );
    }

    console.log("Finished deleting orchestras");
  } catch (err) {
    console.log(err);
  }
};

export default deleteAllOrchestras;
