import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import { createConcert, createProgramPiece } from "graphql/mutations";
import ConcertForm from "admin/components/ConcertForm";
import paths from "admin/paths";
import { getConcert } from "graphql/queries";

const RegisterConcert = () => {
  const history = useHistory();
  const location = useLocation();
  const [initializing, setInitializing] = useState(true);
  const [copied, setCopied] = useState();

  useEffect(() => {
    const fetchOriginal = async (id) => {
      const results = await API.graphql(graphqlOperation(getConcert, { id }));
      console.log(results);
      const {
        orchestraId,
        venue,
        venuePrefecture,
        program,
      } = results.data.getConcert;

      setCopied({ orchestraId, venue, venuePrefecture, program });
      setInitializing(false);
    };

    const orignalId = new URLSearchParams(location.search).get("from");
    if (orignalId) {
      fetchOriginal(orignalId);
    } else {
      setInitializing(false);
    }
  }, [location]);

  const handleSubmit = async (input) => {
    try {
      const program = input.program;
      delete input.program;

      const result = await API.graphql(
        graphqlOperation(createConcert, { input })
      );

      const concert = result.data.createConcert;

      // programの登録
      for (let i = 0; i < program.length; i++) {
        const programPieceInput = {
          pieceId: program[i].pieceId,
          concertId: concert.id,
          order: i + 1,
        };
        await API.graphql(
          graphqlOperation(createProgramPiece, { input: programPieceInput })
        );
      }

      history.push(paths.viewConcert(concert.id));
    } catch (err) {
      console.error(err);
    }
  };

  if (initializing) return <div>Initializing...</div>;

  return (
    <div>
      <h1>Register Concert</h1>
      <ConcertForm initialState={copied} onSubmit={handleSubmit} />
    </div>
  );
};

export default RegisterConcert;
