import React from "react";
import { IconButton, Grid } from "@material-ui/core";
import { ArrowUpward, ArrowDownward, Clear } from "@material-ui/icons";
import PieceSelectorField from "admin/components/PieceSelector/PieceSelectorField";

const ProgramPiece = ({
  composers,
  value,
  onChange,
  onUp,
  onDown,
  onRemove,
  disableUp,
  disableDown,
  disableRemove,
}) => {
  return (
    <Grid container>
      <Grid item xs={6} sm={8}>
        <PieceSelectorField
          composers={composers}
          variant="outlined"
          fullWidth
          value={value}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <IconButton onClick={onUp} disabled={disableUp}>
          <ArrowUpward />
        </IconButton>
        <IconButton onClick={onDown} disabled={disableDown}>
          <ArrowDownward />
        </IconButton>
        {!disableRemove && (
          <IconButton onClick={onRemove}>
            <Clear />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default ProgramPiece;
