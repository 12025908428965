export const regions = [
  { code: 1, name: "北海道" },
  { code: 2, name: "東北" },
  { code: 3, name: "関東" },
  { code: 4, name: "中部" },
  { code: 5, name: "近畿" },
  { code: 6, name: "中国" },
  { code: 7, name: "四国" },
  { code: 8, name: "九州・沖縄" },
];

export const prefectures = [
  { code: 1, name: "北海道", region: 1, nameEn: "Hokkaido" },
  { code: 2, name: "青森県", region: 2, nameEn: "Aomori" },
  { code: 3, name: "岩手県", region: 2, nameEn: "Iwate" },
  { code: 4, name: "宮城県", region: 2, nameEn: "Miyagi" },
  { code: 5, name: "秋田県", region: 2, nameEn: "Akita" },
  { code: 6, name: "山形県", region: 2, nameEn: "Yamagata" },
  { code: 7, name: "福島県", region: 2, nameEn: "Fukushima" },
  { code: 8, name: "茨城県", region: 3, nameEn: "Ibaraki" },
  { code: 9, name: "栃木県", region: 3, nameEn: "Tochigi" },
  { code: 10, name: "群馬県", region: 3, nameEn: "Gunma" },
  { code: 11, name: "埼玉県", region: 3, nameEn: "Saitama" },
  { code: 12, name: "千葉県", region: 3, nameEn: "Chiba" },
  { code: 13, name: "東京都", region: 3, nameEn: "Tokyo" },
  { code: 14, name: "神奈川県", region: 3, nameEn: "Kanagawa" },
  { code: 15, name: "新潟県", region: 4, nameEn: "Niigata" },
  { code: 16, name: "富山県", region: 4, nameEn: "Toyama" },
  { code: 17, name: "石川県", region: 4, nameEn: "Ishikawa" },
  { code: 18, name: "福井県", region: 4, nameEn: "Fukui" },
  { code: 19, name: "山梨県", region: 4, nameEn: "Yamanashi" },
  { code: 20, name: "長野県", region: 4, nameEn: "Nagano" },
  { code: 21, name: "岐阜県", region: 4, nameEn: "Gifu" },
  { code: 22, name: "静岡県", region: 4, nameEn: "Shizuoka" },
  { code: 23, name: "愛知県", region: 4, nameEn: "Aichi" },
  { code: 24, name: "三重県", region: 5, nameEn: "Mie" },
  { code: 25, name: "滋賀県", region: 5, nameEn: "Shiga" },
  { code: 26, name: "京都府", region: 5, nameEn: "Kyoto" },
  { code: 27, name: "大阪府", region: 5, nameEn: "Osaka" },
  { code: 28, name: "兵庫県", region: 5, nameEn: "Hyogo" },
  { code: 29, name: "奈良県", region: 5, nameEn: "Nara" },
  { code: 30, name: "和歌山県", region: 5, nameEn: "Wakayama" },
  { code: 31, name: "鳥取県", region: 6, nameEn: "Tottori" },
  { code: 32, name: "島根県", region: 6, nameEn: "Shimane" },
  { code: 33, name: "岡山県", region: 6, nameEn: "Okayama" },
  { code: 34, name: "広島県", region: 6, nameEn: "Hiroshima" },
  { code: 35, name: "山口県", region: 6, nameEn: "Yamaguchi" },
  { code: 36, name: "徳島県", region: 7, nameEn: "Tokushima" },
  { code: 37, name: "香川県", region: 7, nameEn: "Kagawa" },
  { code: 38, name: "愛媛県", region: 7, nameEn: "Ehime" },
  { code: 39, name: "高知県", region: 7, nameEn: "Kochi" },
  { code: 40, name: "福岡県", region: 8, nameEn: "Fukuoka" },
  { code: 41, name: "佐賀県", region: 8, nameEn: "Saga" },
  { code: 42, name: "長崎県", region: 8, nameEn: "Nagasaki" },
  { code: 43, name: "熊本県", region: 8, nameEn: "Kumamoto" },
  { code: 44, name: "大分県", region: 8, nameEn: "Oita" },
  { code: 45, name: "宮崎県", region: 8, nameEn: "Miyazaki" },
  { code: 46, name: "鹿児島県", region: 8, nameEn: "Kagoshima" },
  { code: 47, name: "沖縄県", region: 8, nameEn: "Okinawa" },
];

export function getPrefectureCode(locationPath) {
  const [country, area] = locationPath.split("/");

  if (country === "Japan") {
    const pref = prefectures.find((p) => p.nameEn === area);
    return pref && pref.code;
  } else {
    return null;
  }
}

export function formatLocationPath(locationPath, lang = "ja") {
  const [country, area] = locationPath.split("/");

  if (lang === "ja") {
    if (country === "Japan") {
      return prefectures.find((p) => p.nameEn === area).name;
    }
    return `${area}, ${country}`;
  } else {
    return `${area}, ${country}`;
  }
}

// zero-paddingした2桁の文字列コードにする
export function toStringCode(numberCode) {
  const code = `0${numberCode}`;
  return code.substr(code.length - 2);
}
