import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { API, graphqlOperation } from "aws-amplify";
import { deleteOrchestra } from "graphql/mutations";
import useOrchestra from "hooks/useOrchestra";
import QueryResultHandler from "components/QueryResultHandler";
import ConfirmationButton from "components/ConfirmationButton";
import paths from "admin/paths";

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const Orchestra = ({ orchestra }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleDelete = async () => {
    const input = { id: orchestra.id };
    try {
      await API.graphql(graphqlOperation(deleteOrchestra, { input }));
      history.push(paths.listOrchestras());
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <h1>{orchestra.nameJa}</h1>
      <h4>{orchestra.nameEn}</h4>
      <p>
        <a href={orchestra.website} target="_blank" rel="noreferrer">
          {orchestra.website}
        </a>
      </p>
      <div className={classes.actionButtons}>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to={paths.editOrchestra(orchestra.id)}
        >
          Edit
        </Button>
        <ConfirmationButton
          variant="outlined"
          color="secondary"
          onOk={handleDelete}
          title="オーケストラの削除"
          message={`『${orchestra.nameJa}』を削除します。`}
        >
          Delete
        </ConfirmationButton>
      </div>
      <h4>コンサート</h4>
      {orchestra.concerts.items.map((concert) => (
        <div key={concert.id}>{concert.nameJa}</div>
      ))}
    </div>
  );
};

const ViewOrchestra = () => {
  const { id } = useParams();
  const { orchestra, ...queryResult } = useOrchestra(id);

  return (
    <QueryResultHandler {...queryResult}>
      <Orchestra orchestra={orchestra} />
    </QueryResultHandler>
  );
};

export default ViewOrchestra;
