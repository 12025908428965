import { API, graphqlOperation } from "aws-amplify";
import { listConcerts } from "graphql/queries";
import { deleteConcert, deleteProgramPiece } from "graphql/mutations";
import executeMutation from "./execute-mutation";

const executeDeleteProgramPiece = async (programPieceId) => {
  const input = {
    id: programPieceId,
  };
  return await executeMutation(deleteProgramPiece, input, "deleteProgramPiece");
};

const executeDeleteConcert = async (concertId) => {
  const input = {
    id: concertId,
  };
  return await executeMutation(deleteConcert, input, "deleteConcert");
};

export const deleteAllConcerts = async () => {
  const report = {
    programPiece: {
      delete: 0,
      errors: [],
    },
    concert: {
      delete: 0,
      skipDueToPieceDeletionError: 0,
      errors: [],
    },
  };

  try {
    console.log("Started deleting concerts");

    const result = await API.graphql(graphqlOperation(listConcerts));
    if (result.errors) throw result.errors;

    const concerts = result.data.listConcerts.items;
    for (const concert of concerts) {
      let programPieceDeletionError = false;

      // ProgramPieceを先に削除
      for (const programPiece of concert.program.items) {
        const { errors } = executeDeleteProgramPiece(programPiece.id);
        if (errors) {
          report.programPiece.errors.push(errors);
          programPieceDeletionError = true;
        } else {
          report.programPiece.delete++;
        }
      }

      // pieceが完全に削除できなかった場合、データ不整合になるた(め削除しない
      if (programPieceDeletionError) {
        report.concert.skipDueToPieceDeletionError++;
      } else {
        const { errors } = executeDeleteConcert(concert.id);
        if (errors) {
          report.concert.errors.push(errors);
        } else {
          report.concert.delete++;
        }
      }
    }

    console.log("Finished deleting concerts");
    console.log(report);
  } catch (err) {
    console.log(err);
  }
};

export default deleteAllConcerts;
