import { useState } from "react";

const useComposerForm = ({ initialState, onSubmit }) => {
  const [formState, setFormState] = useState({
    nameJa: initialState?.nameJa || "",
    longNameJa: initialState?.longNameJa || "",
    nameLatin: initialState?.nameLatin || "",
    longNameLatin: initialState?.longNameLatin || "",
  });

  const handleChange = (key) => (e) => {
    setFormState((state) => ({ ...state, [key]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onSubmit(formState);
  };

  const isValidInput = formState.nameJa;

  return { formState, handleChange, handleSubmit, isValidInput };
};

export default useComposerForm;
