import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { pageview } from "utils/gtag";

export const GAWrapper = ({ children }) => {
  const { listen } = useHistory();

  useEffect(() => {
    const unlisten = listen((location) => {
      pageview(location.pathname);
    });
    return unlisten;
  }, [listen]);

  return <>{children}</>;
};
