import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ComposerSelectorField from "admin/components/ComposerSelector/ComposerSelectorField";
import findPieceComposer from "./utils/findPieceComposer";

const PieceSelectorPane = ({ composers, value, onChange }) => {
  const [composer, setComposer] = useState(findPieceComposer(value, composers));

  const handleComposerChange = (composerId) => {
    setComposer(composers.find((composer) => composer.id === composerId));
  };

  const handleChange = (_e, piece) => {
    if (onChange) onChange(piece.id);
  };

  const composerId = composer?.id || "";
  const pieces = composer?.pieces.items || [];

  return (
    <div style={{ width: 400 }}>
      <ComposerSelectorField
        label="作曲家"
        variant="outlined"
        fullWidth
        composers={composers}
        value={composerId}
        onChange={handleComposerChange}
      />
      <Autocomplete
        value={pieces.find((piece) => piece.id === value) ?? null}
        onChange={handleChange}
        options={pieces}
        getOptionLabel={(piece) => `${piece.longNameJa} / ${piece.longNameEn}`}
        renderInput={(params) => (
          <TextField variant="outlined" fullWidth {...params} />
        )}
      />
    </div>
  );
};

export default PieceSelectorPane;
