import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { API, graphqlOperation } from "aws-amplify";
import { deleteConcert, deleteProgramPiece } from "graphql/mutations";
import useConcert from "hooks/useConcert";
import QueryResultHandler from "components/QueryResultHandler";
import ConfirmationButton from "components/ConfirmationButton";
import { KeyValueDataTable } from "components/KeyValueDataTable";
import paths from "admin/paths";
import formatDateTime from "utils/formatDateTime";
import { getLabelFromCode } from "utils/prefectures";

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const Concert = ({ concert }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleDelete = async () => {
    try {
      // その前にProgramPieceを全部削除する必要がある。
      for (const programPiece of concert.program.items) {
        await API.graphql(
          graphqlOperation(deleteProgramPiece, {
            input: { id: programPiece.id },
          })
        );
      }

      const input = { id: concert.id };
      await API.graphql(graphqlOperation(deleteConcert, { input }));
      history.push(paths.listConcerts());
    } catch (err) {
      console.error(err);
    }
  };

  const {
    nameJa,
    nameEn,
    date,
    orchestra,
    website,
    venue,
    venuePrefecture,
    conductor,
    note,
  } = concert;

  const data = [
    { label: "コンサート名", value: nameJa },
    { label: "コンサート名（英語表記）", value: nameEn },
    { label: "日時", value: formatDateTime(date) },
    { label: "オーケストラ", value: orchestra.nameJa },
    { label: "Webサイト", value: website, link: true },
    { label: "会場", value: `${venue} (${getLabelFromCode(venuePrefecture)})` },
    { label: "指揮者", value: conductor },
    { label: "備考", value: note },
  ];

  return (
    <div>
      <KeyValueDataTable data={data} />
      <div className={classes.actionButtons}>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to={paths.editConcert(concert.id)}
        >
          Edit
        </Button>
        <ConfirmationButton
          variant="outlined"
          color="secondary"
          onOk={handleDelete}
          title="コンサートの削除"
          message={`『${concert.nameJa}』を削除します。`}
        >
          Delete
        </ConfirmationButton>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to={`${paths.registerConcert()}?from=${concert.id}`}
        >
          Copy
        </Button>
      </div>
      <h4>プログラム</h4>
      {concert.program.items.map(({ piece }) => (
        <div key={piece.id}>
          {piece.longNameJa} ({piece.composer.longNameJa})
        </div>
      ))}
    </div>
  );
};

const ViewConcert = () => {
  const { id } = useParams();
  const { concert, ...queryResult } = useConcert(id);

  return (
    <QueryResultHandler {...queryResult}>
      <Concert concert={concert} />
    </QueryResultHandler>
  );
};

export default ViewConcert;
