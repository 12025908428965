import { createConcert, createProgramPiece } from "graphql/mutations";
import executeMutation from "./execute-mutation";

// 仕様
// データの載せ替えに使用する（yamlを手書きすることは前提としない）
// idがあってもなくても新規登録とする
// 使用時にはコンサートデータが全くない前提
// オーケストラと曲は登録ずみである前提

const executeCreateConcert = async (concert) => {
  const input = {
    id: concert.id || undefined,
    orchestraId: concert.orchestraId,
    nameJa: concert.nameJa,
    nameEn: concert.nameEn || "",
    date: concert.date || "",
    venue: concert.venue || "",
    venuePrefecture: concert.venuePrefecture || "",
    website: concert.website || "",
    note: concert.note,
  };
  return await executeMutation(createConcert, input, "createConcert");
};

const executeCreateProgramPiece = async (concertId, pieceId, order) => {
  const input = {
    concertId,
    pieceId,
    order,
  };
  return await executeMutation(createProgramPiece, input, "createProgramPiece");
};

export const importConcerts = async (data) => {
  console.log(data);

  const report = {
    concerts: { create: 0 },
    programPieces: { create: 0 },
    errors: [],
  };

  try {
    console.log("Started registering concerts");

    for (const concert of data) {
      if (!concert.nameJa) continue;

      const { data, errors } = await executeCreateConcert(concert);
      if (errors) {
        report.errors.push(errors);
        continue;
      } else {
        report.concerts.create++;
      }

      const concertId = data.createConcert.id;

      // programの登録
      for (let i = 0; i < concert.pieceIds.length; i++) {
        const pieceId = concert.pieceIds[i];
        const order = i + 1;
        const { errors } = await executeCreateProgramPiece(
          concertId,
          pieceId,
          order
        );
        if (errors) {
          report.errors.push(errors);
        } else {
          report.programPieces.create++;
        }
      }
    }
    console.log("Finished registering concerts");
    console.log(report);
  } catch (err) {
    console.log(err);
  }
};

export default importConcerts;
