import { getComposer } from "graphql/queries";
import useQuery from "./useQuery";

const useComposer = (id) => {
  const { data, errors, loading } = useQuery(getComposer, { id });

  const composer = data?.getComposer ?? null;

  if (composer) {
    composer.pieces.items.sort((a, b) => a.nameJa.localeCompare(b.nameJa));
  }

  return { composer, errors, loading };
};

export default useComposer;
