import { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import OrchestraBatchJobs from "./OrchestraBatchJobs";
import ComposerBatchJobs from "./ComposerBatchJobs";
import ConcertBatchJobs from "./ConcertBatchJobs";

const BatchJobs = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <h1>Batch Jobs</h1>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Orchestras" />
        <Tab label="Composers / Pieces" />
        <Tab label="Concerts" />
      </Tabs>
      {tabValue === 0 && <OrchestraBatchJobs />}
      {tabValue === 1 && <ComposerBatchJobs />}
      {tabValue === 2 && <ConcertBatchJobs />}
    </div>
  );
};

export default BatchJobs;
