import YamlLoader from "admin/components/YamlLoader";
import ConfirmationButton from "components/ConfirmationButton";
import {
  deleteAllOrchestras,
  importOrchestras,
  exportOrchestras,
} from "./batch-operations";

const OrchestraBatchJobs = () => {
  const handleDeleteOrchestras = async () => {
    await deleteAllOrchestras();
  };

  const handleImportOrchestras = async (data) => {
    await importOrchestras(data);
  };

  const handleExportOrchestras = async () => {
    await exportOrchestras();
  };

  return (
    <div>
      <h3>Delete All Orchestras</h3>
      <p>先にコンサート情報を削除してください。</p>
      <ConfirmationButton
        message="すべてのオーケストラを削除します。"
        onOk={handleDeleteOrchestras}
        variant="contained"
      >
        Delete
      </ConfirmationButton>

      <h3>Import Orchestras</h3>
      <YamlLoader onLoad={handleImportOrchestras} />

      <h3>Export Orchestras</h3>
      <ConfirmationButton
        message="オーケストラのデータをエクスポートします。"
        onOk={handleExportOrchestras}
        variant="contained"
      >
        Export
      </ConfirmationButton>
    </div>
  );
};

export default OrchestraBatchJobs;
