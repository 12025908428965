import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { createOrchestra } from "graphql/mutations";
import OrchestraForm from "admin/components/OrchestraForm";
import paths from "admin/paths";

const RegisterOrchestra = () => {
  const history = useHistory();

  const handleSubmit = async (input) => {
    try {
      const result = await API.graphql(
        graphqlOperation(createOrchestra, { input })
      );
      history.push(paths.viewOrchestra(result.data.createOrchestra.id));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <h1>Register Orchestra</h1>
      <OrchestraForm onSubmit={handleSubmit} />
    </div>
  );
};

export default RegisterOrchestra;
