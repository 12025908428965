import React from "react";
import ErrorNotification from "./ErrorNotification";
import LoadingSpinner from "./LoadingSpinner";
import NotFound from "../NotFound";

// ToDo: モデルごとのhookへの移行が完了したら不要な機能は捨てる
// 名前も変えた方がいいかもしれない。
// 残す機能はerrorsとloadingを処理するだけ。
// 複数のクエリを使うページの、複数の結果をマージする機能があるといいかも。
// loadingをorでまとめる。errorsの配列を合体する。

// これ自体hookにできない？
// JSX.Elementを返す。
// const somethingToRender = useQueryHandler({data, errors, loading})
// if (somethingToRender) return somethingToRender
// あまり美しくないか。

const QueryResultHandler = ({
  data,
  loading,
  errors,
  children,
  queryName,
  notFound,
}) => {
  if (loading) {
    return <LoadingSpinner />;
  }

  if (errors) {
    return (
      <>
        {errors.map((error, index) => (
          <ErrorNotification error={error} key={index} />
        ))}
      </>
    );
  }

  // dataが供給されている場合。
  // モデルごとのuseXxxの導入により、dataを渡さない使い方に対応
  if (data) {
    const queryData = queryName ? data[queryName] : data;

    if (notFound && queryData == null) {
      if (notFound === true) {
        return <NotFound />;
      }
      return notFound;
    }
    return typeof children == "function" ? children(queryData) : children;
  } else {
    return children;
  }
};

export default QueryResultHandler;
