import { Box } from "@material-ui/core";

export const KeyValueDataTable = ({ data }) => {
  return (
    <Box mb={4}>
      {data.map(({ label, value, link }) => {
        return (
          <Box display="flex" flexWrap="wrap" mb={1}>
            <Box fontWeight="bold" minWidth={200}>
              {label}
            </Box>
            {link && value ? (
              <Box minWidth={200}>
                <a
                  href={value}
                  target="_blank"
                  rel="noreferrer"
                  style={{ wordWrap: "break-word" }}
                >
                  {value}
                </a>
              </Box>
            ) : (
              <Box whiteSpace="pre-line">{value}</Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
