import { API, graphqlOperation } from "aws-amplify";
import { listConcerts } from "graphql/queries";
import yaml from "js-yaml";
import downloadFile from "./download-file";
import { timestamp } from "./timestamp";

export const exportConcerts = async () => {
  try {
    const result = await API.graphql(graphqlOperation(listConcerts));
    if (result.errors) throw result.errors;
    const concerts = result.data.listConcerts.items;

    // 必要なデータだけ取り出す
    const data = concerts.map((concert) => {
      const {
        id,
        orchestraId,
        nameJa,
        nameEn,
        date,
        venue,
        venuePrefecture,
        website,
        note,
        program,
      } = concert;
      const pieceIds = program.items.map(
        (programPiece) => programPiece.pieceId
      );
      return {
        id,
        orchestraId,
        nameJa,
        nameEn,
        date,
        venue,
        venuePrefecture,
        website,
        note,
        pieceIds,
      };
    });

    downloadFile(yaml.dump(data), `concerts-exported-${timestamp()}.yml`);
  } catch (err) {
    console.log(err);
  }
};

export default exportConcerts;
