import {
  format,
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  getDay,
  isSameMonth,
} from "date-fns";
import styled from "styled-components";
import { getContentKey } from "./helpers";

const DaysContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(7, 1fr);
  margin: 20px;
`;

const DayContainer = styled.div`
  min-height: 100px;
  margin: 4px;
  padding: 4px;
  border: 1px solid #ddd;
  background-color: ${({ outOfMonth, dayOfWeek }) =>
    outOfMonth
      ? "#d6d6d6"
      : dayOfWeek === 0
      ? "#fee"
      : dayOfWeek === 6
      ? "#eef"
      : "#fff"};
  color: ${({ outOfMonth, dayOfWeek }) => (outOfMonth ? "#999" : "black")};
`;

const Day = ({ day, content, outOfMonth }) => {
  return (
    <DayContainer dayOfWeek={getDay(day)} outOfMonth={outOfMonth}>
      <div>{format(day, "d")}</div>
      <div>{content}</div>
    </DayContainer>
  );
};

const DayOfWeek = styled.div`
  font-weight: bold;
  text-align: center;
  margin: 4px;
  padding: 4px;
  border: 1px solid #ddd;
`;

// contents: { "2000-10-01": "content", ... }
const MonthlyCalendar = ({ targetMonth, contents }) => {
  const monthInterval = {
    start: startOfWeek(startOfMonth(targetMonth), { weekStartsOn: 1 }),
    end: endOfWeek(endOfMonth(targetMonth), { weekStartsOn: 1 }),
  };

  return (
    <div>
      <DaysContainer>
        <DayOfWeek>月</DayOfWeek>
        <DayOfWeek>火</DayOfWeek>
        <DayOfWeek>水</DayOfWeek>
        <DayOfWeek>木</DayOfWeek>
        <DayOfWeek>金</DayOfWeek>
        <DayOfWeek>土</DayOfWeek>
        <DayOfWeek>日</DayOfWeek>
        {eachDayOfInterval(monthInterval).map((day) => (
          <Day
            key={day}
            day={day}
            content={contents[getContentKey(day)]}
            outOfMonth={!isSameMonth(day, targetMonth)}
          />
        ))}
      </DaysContainer>
    </div>
  );
};

export default MonthlyCalendar;
