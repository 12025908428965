import styled from "styled-components";
import { addMonths, compareAsc } from "date-fns";
import { Button } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

const MonthSelectorLayout = styled.div`
  text-align: center;
`;

export const MonthSelector = ({ value, onChange }) => {
  const minDate = new Date("2021-01-01T00:00:00");
  const maxDate = new Date("2022-12-31T00:00:00");

  const handlePrev = () => {
    const newDate = addMonths(value, -1);
    console.log(newDate, minDate);
    if (compareAsc(newDate, minDate) >= 0) {
      onChange(newDate);
    }
  };

  const handleNext = () => {
    const newDate = addMonths(value, 1);
    if (compareAsc(newDate, maxDate) <= 0) {
      onChange(newDate);
    }
  };

  return (
    <MonthSelectorLayout>
      <Button onClick={handlePrev}>
        <ChevronLeft />
      </Button>
      <DatePicker
        views={["year", "month"]}
        minDate={minDate}
        maxDate={maxDate}
        format="yyyy年 M月"
        openTo="month"
        autoOk
        inputProps={{ style: { textAlign: "center" } }}
        value={value}
        onChange={onChange}
      />
      <Button onClick={handleNext}>
        <ChevronRight />
      </Button>
    </MonthSelectorLayout>
  );
};
