import React from "react";
import { MenuItem, TextField } from "@material-ui/core";

const OrchestraSelectorField = ({ orchestras, ...props }) => {
  // 現在対応しているオーケストラだけを表示するのと表示順のコントロール
  // 美しくないが、とりあえずこれで凌ぐ
  const sortedOrchestras = [
    "NHK交響楽団",
    "読売日本交響楽団",
    "東京フィルハーモニー交響楽団",
    "東京都交響楽団",
  ]
    .map((name) => orchestras.find((o) => o.nameJa === name))
    .filter((item) => item != null);

  return (
    <TextField select {...props}>
      {sortedOrchestras.map((orchestra) => (
        <MenuItem key={orchestra.id} value={orchestra.id}>
          {orchestra.nameJa}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default OrchestraSelectorField;
