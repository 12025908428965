import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { API, graphqlOperation } from "aws-amplify";
import { getComposer } from "graphql/queries";
import { deleteComposer } from "graphql/mutations";
import useComposer from "hooks/useComposer";
import QueryResultHandler from "components/QueryResultHandler";
import ConfirmationButton from "components/ConfirmationButton";
import paths from "admin/paths";

const Piece = ({ piece }) => {
  return (
    <div>
      <Link to={paths.viewPiece(piece.id)}>{piece.longNameJa}</Link>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const Composer = ({ composer }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleDelete = async () => {
    const input = { id: composer.id };
    try {
      // 削除する前に、曲が全部削除されていることを確認
      const latestState = await API.graphql(
        graphqlOperation(getComposer, { id: composer.id })
      );
      if (latestState.data.getComposer.pieces.items.length > 0) {
        throw new Error("The composer still has one or more pieces.");
      }

      await API.graphql(graphqlOperation(deleteComposer, { input }));
      history.push(paths.listComposers());
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <h1>{composer.nameJa}</h1>
      <h5>{composer.longNameJa}</h5>
      <h3>{composer.nameLatin}</h3>
      <h5>{composer.longNameLatin}</h5>
      <div className={classes.actionButtons}>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to={paths.editComposer(composer.id)}
        >
          Edit
        </Button>
        <ConfirmationButton
          variant="outlined"
          color="secondary"
          onOk={handleDelete}
          title="作曲家の削除"
          message={`『${composer.nameJa}』を削除します。`}
          disabled={composer.pieces.items.length > 0}
        >
          Delete
        </ConfirmationButton>
      </div>
      <h3>Pieces</h3>
      <div>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to={`${paths.registerPiece()}?composer=${composer.id}`}
        >
          Add
        </Button>
      </div>
      <div>
        {composer.pieces.items.map((piece) => (
          <Piece piece={piece} key={piece.id} />
        ))}
      </div>
    </div>
  );
};

const ViewComposer = () => {
  const { id } = useParams();
  const { composer, ...queryResult } = useComposer(id);

  return (
    <QueryResultHandler {...queryResult}>
      <Composer composer={composer} />
    </QueryResultHandler>
  );
};

export default ViewComposer;
