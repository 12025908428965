import React from "react";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import paths from "admin/paths";

const AdminNav = () => {
  return (
    <nav>
      <Container>
        <Grid container spacing={4}>
          <Grid item style={{ flexGrow: "1" }}>
            <Link to={paths.listComposers()}>Composers</Link>
            {" | "}
            <Link to={paths.listPieces}>Pieces</Link>
            {" | "}
            <Link to={paths.listOrchestras}>Orchestras</Link>
            {" | "}
            <Link to={paths.listConcerts}>Concerts</Link> (
            <Link to={paths.registerConcert}>New</Link>){" | "}
            <Link to={paths.batchJobs}>Batch Jobs</Link>
          </Grid>
          <Grid item>
            <AmplifySignOut />
          </Grid>
        </Grid>
        <hr />
      </Container>
    </nav>
  );
};

export default AdminNav;
