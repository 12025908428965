import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { PrefectureSelector } from "admin/components/PrefectureSelector";
import OrchestraSelector from "admin/components/OrchestraSelector";
import ProgramPanel from "./ProgramPanel";
import useConcertForm from "./useConcertForm";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const ConcertForm = ({ initialState, onSubmit }) => {
  const classes = useStyles();

  const { formState, handleChange, handleSubmit, isValidInput } =
    useConcertForm({
      initialState,
      onSubmit,
    });

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <OrchestraSelector
        label="オーケストラ"
        variant="outlined"
        fullWidth
        value={formState.orchestraId}
        onChange={handleChange("orchestraId")}
      />
      <TextField
        label="コンサート名"
        variant="outlined"
        fullWidth
        value={formState.nameJa}
        onChange={handleChange("nameJa")}
      />
      <TextField
        label="コンサート名（英語表記）"
        variant="outlined"
        fullWidth
        value={formState.nameEn}
        onChange={handleChange("nameEn")}
      />
      <DateTimePicker
        label="日時"
        inputVariant="outlined"
        fullWidth
        ampm={false}
        clearable
        minutesStep={5}
        format="yyyy/MM/dd HH:mm"
        value={formState.date}
        onChange={handleChange("date")}
      />
      <TextField
        label="コンサート会場"
        variant="outlined"
        fullWidth
        value={formState.venue}
        onChange={handleChange("venue")}
      />
      <PrefectureSelector
        label="都道府県"
        variant="outlined"
        fullWidth
        value={formState.venuePrefecture}
        onChange={handleChange("venuePrefecture")}
      />
      <TextField
        label="ウェブサイト"
        variant="outlined"
        fullWidth
        value={formState.website}
        onChange={handleChange("website")}
      />
      <TextField
        label="指揮者"
        variant="outlined"
        fullWidth
        value={formState.conductor}
        onChange={handleChange("conductor")}
      />
      <TextField
        label="備考"
        variant="outlined"
        fullWidth
        multiline
        rows={5}
        value={formState.note}
        onChange={handleChange("note")}
      />
      <h4>プログラム</h4>
      <ProgramPanel
        value={formState.program}
        onChange={handleChange("program")}
      />
      <Button
        variant="contained"
        color="primary"
        disabled={!isValidInput}
        type="submit"
      >
        登録
      </Button>
    </form>
  );
};

export default ConcertForm;
