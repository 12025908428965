import { API, graphqlOperation } from "aws-amplify";
import { listOrchestras } from "graphql/queries";
import { createOrchestra, updateOrchestra } from "graphql/mutations";
import executeMutation from "./execute-mutation";

// 仕様
// idがないデータは新規として登録
// ただし、名前が同じデータがあったらスキップ
// idがあるものは更新処理
// すでに登録されているデータはインポートデータに存在しなくても削除はしない

const findById = (orchestras, item) => {
  return orchestras.find((orchestra) => orchestra.id === item.id);
};

const findByNameJa = (orchestras, item) => {
  return orchestras.find((orchestra) => orchestra.nameJa === item.nameJa);
};

const hasSameValues = (a, b) => {
  return (
    a.id === b.id &&
    a.nameJa === b.nameJa &&
    a.nameEn === b.nameEn &&
    a.website === b.website
  );
};

const executeCreateOrchestra = async (orchestra) => {
  const input = {
    id: orchestra.id || undefined,
    nameJa: orchestra.nameJa,
    nameEn: orchestra.nameEn || "",
    website: orchestra.website || "",
  };
  return await executeMutation(createOrchestra, input, "createOrchestra");
};

const executeUpdateOrchestra = async (orchestra) => {
  const input = {
    id: orchestra.id,
    nameJa: orchestra.nameJa,
    nameEn: orchestra.nameEn || "",
    website: orchestra.website || "",
  };
  return await executeMutation(updateOrchestra, input, "updateOrchestra");
};

export const importOrchestras = async (data) => {
  console.log(data);

  const report = {
    create: 0,
    update: 0,
    skip: 0,
    skipDueToNameConflict: 0,
    errors: [],
  };

  try {
    console.log("Started registering orchestras");

    const result = await API.graphql(graphqlOperation(listOrchestras));
    if (result.errors) throw result.errors;
    const existingOrchestras = result.data.listOrchestras.items;

    for (const orchestra of data) {
      if (!orchestra.nameJa) continue; // Placeholderとして空のレコードがある

      const existingItem = findById(existingOrchestras, orchestra);

      if (!existingItem) {
        // 存在しない -> create
        if (findByNameJa(existingOrchestras, orchestra) != null) {
          // ただし、同名のデータが存在する場合はスキップ
          report.skipDueToNameConflict++;
        } else {
          const { errors } = await executeCreateOrchestra(orchestra);
          if (errors) {
            report.errors.push(errors);
          } else {
            report.create++;
          }
        }
      } else if (!hasSameValues(orchestra, existingItem)) {
        // 存在するが値が変わってる -> update
        const { errors } = await executeUpdateOrchestra(orchestra);
        if (errors) {
          report.errors.push(errors);
        } else {
          report.update++;
        }
      } else {
        // 同じデータが存在する -> 何もしない
        report.skip++;
      }
    }
    console.log("Finished registering orchestras");
    console.log(report);
  } catch (err) {
    console.log(err);
  }
};

export default importOrchestras;
