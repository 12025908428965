import { getConcert } from "graphql/queries";
import useQuery from "./useQuery";

const useConcert = (id) => {
  const { data, errors, loading } = useQuery(getConcert, { id });

  const concert = data?.getConcert ?? null;

  return { concert, errors, loading };
};

export default useConcert;
