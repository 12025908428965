import { API, graphqlOperation } from "aws-amplify";

// エラー処理を統一的に扱うためのヘルパ

const executeMutation = async (mutation, input, mutationName) => {
  try {
    // returns { data: {...} }
    return await API.graphql(graphqlOperation(mutation, { input }));
  } catch (err) {
    if (err.errors) {
      // GraphQL errors
      // `err` looks like { data: null, errors: [ ... ] }
      return {
        errors: {
          mutationName,
          input,
          ...err,
        },
      };
    } else {
      // unknown -> rethrow
      throw err;
    }
  }
};

export default executeMutation;
