import { useState } from "react";
import { Box, Button, Dialog, Chip } from "@material-ui/core";
import { Done as DoneIcon } from "@material-ui/icons";
import useOrchestras from "hooks/useOrchestras";
import { getOrchestraAbbreviatedName } from "utils/getOrchestraAbbreviatedName";

export const OrchestraFilter = ({ value, onChange }) => {
  const [open, setOpen] = useState(false);
  const { orchestras } = useOrchestras();

  // 現在対応しているオーケストラだけを表示するのと表示順のコントロール
  // 美しくないが、とりあえずこれで凌ぐ
  const sortedOrchestras = [
    "NHK交響楽団",
    "読売日本交響楽団",
    "東京フィルハーモニー交響楽団",
    "東京都交響楽団",
  ]
    .map((name) => orchestras.find((o) => o.nameJa === name))
    .filter((item) => item != null);

  const handleClick = (id) => () => {
    let newValue = [];
    if (value.includes(id)) {
      newValue = value.filter((v) => v !== id);
    } else {
      newValue = [...value, id];
    }

    // 対象外のデータがあれば削除する
    //（将来sortedOrchestrasの値が変わったときにlocalStorageに古い値が入ってたりした場合に備える）
    onChange(
      newValue.filter((v) => sortedOrchestras.some((so) => so.id === v))
    );
  };

  return (
    <Box mb={2}>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => setOpen(true)}
      >
        オーケストラで絞り込み
      </Button>
      <Box display="flex" flexWrap="wrap">
        {sortedOrchestras
          .filter((so) => value.includes(so.id))
          .map(({ id, nameJa }) => (
            <Box key={id} mr={1} mt={1}>
              <Chip label={getOrchestraAbbreviatedName(nameJa)} size="small" />
            </Box>
          ))}
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box p={2}>
          {sortedOrchestras.map(({ id, nameJa }) => (
            <Box key={id} mb={1}>
              <Chip
                label={nameJa}
                clickable
                onClick={handleClick(id)}
                icon={value.includes(id) ? <DoneIcon /> : undefined}
              />
            </Box>
          ))}
        </Box>
        <Box textAlign="center" mb={2}>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            閉じる
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};
