import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { format, startOfMonth, endOfMonth } from "date-fns";
import useConcerts from "hooks/useConcerts";
import QueryResultHandler from "components/QueryResultHandler";
import paths from "admin/paths";
import formatDateTime from "utils/formatDateTime";
import { getLabelFromCode } from "utils/prefectures";
import { OrchestraFilter } from "components/OrchestraFilter"; // 一般画面のと同じ

const STORAGE_KEY_ORCHESTRAS = "admin/concerts/filter/orchestras";
const STORAGE_KEY_MONTH = "admin/concerts/filter/month";

const useStyles = makeStyles(() => ({
  tableCell: {
    minWidth: 50,
    maxWidth: "20vw",
  },
}));

const Concerts = ({ concerts }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>日時</TableCell>
            <TableCell className={classes.tableCell}>都道府県</TableCell>
            <TableCell className={classes.tableCell}>コンサート名</TableCell>
            <TableCell className={classes.tableCell}>オーケストラ</TableCell>
            <TableCell className={classes.tableCell}>会場</TableCell>
            <TableCell className={classes.tableCell}>指揮者</TableCell>
            <TableCell className={classes.tableCell}>プログラム</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {concerts.map((concert) => (
            <TableRow key={concert.id}>
              <TableCell className={classes.tableCell}>
                {formatDateTime(concert.date)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {getLabelFromCode(concert.venuePrefecture)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Link to={paths.viewConcert(concert.id)}>{concert.nameJa}</Link>
              </TableCell>
              <TableCell className={classes.tableCell}>
                {concert.orchestra.nameJa}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {concert.venue}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {concert.conductor}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <ul>
                  {concert.program.items.map((programPiece) => (
                    <li key={programPiece.id}>
                      {programPiece.piece.composer.nameJa} /{" "}
                      {programPiece.piece.longNameJa}
                    </li>
                  ))}
                </ul>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const getMonthsString = (yearString) => {
  return [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ].map((m) => `${yearString}-${m}`);
};

const MonthFilter = ({ value, onChange }) => {
  const handleChange = (e) => {
    if (e.target.value === "not-selected") {
      onChange(null);
    }

    const result = /^(20\d\d)-(0[1-9]|1[012])$/.exec(e.target.value);
    if (result) {
      const [, y, m] = result;
      const year = parseInt(y, 10);
      const month = parseInt(m, 10);
      onChange(new Date(year, month - 1, 1));
    }
  };

  const months = [getMonthsString("2021"), getMonthsString("2022")].flat();

  return (
    <TextField
      select
      label="年月"
      value={value ? format(value, "yyyy-MM") : "not-selected"}
      onChange={handleChange}
    >
      <MenuItem key="not-selected" value="not-selected">
        フィルタなし
      </MenuItem>
      {months.map((m) => (
        <MenuItem key={m} value={m}>
          {m}
        </MenuItem>
      ))}
    </TextField>
  );
};

const ListConcerts = () => {
  const [selectedMonth, setSelectedMonth] = useState(startOfMonth(new Date()));
  const { concerts, ...queryResult } = useConcerts({
    deep: true,
    variables: {
      filter: selectedMonth
        ? {
            date: {
              between: [
                startOfMonth(selectedMonth).toISOString(),
                endOfMonth(selectedMonth).toISOString(),
              ],
            },
          }
        : undefined,
    },
  });
  const [selectedOrchestras, setSelectedOrchestras] = useState([]);

  useEffect(() => {
    // local strageから読み込む
    const storedOrchestras = localStorage.getItem(STORAGE_KEY_ORCHESTRAS);
    if (storedOrchestras) {
      setSelectedOrchestras(JSON.parse(storedOrchestras));
    }
    const storedMonth = localStorage.getItem(STORAGE_KEY_MONTH);
    if (storedMonth) {
      const jsonParsed = JSON.parse(storedMonth);
      setSelectedMonth(jsonParsed != null ? Date.parse(jsonParsed) : null);
    }
  }, []);

  const handleSelectedOrchestraChange = (value) => {
    setSelectedOrchestras(value);
    localStorage.setItem(STORAGE_KEY_ORCHESTRAS, JSON.stringify(value));
  };

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    localStorage.setItem(STORAGE_KEY_MONTH, JSON.stringify(month));
  };

  let filteredConcerts = concerts;
  if (selectedOrchestras.length > 0) {
    filteredConcerts = filteredConcerts.filter((concert) =>
      selectedOrchestras.includes(concert.orchestra.id)
    );
  }

  return (
    <div>
      <h1>Concerts</h1>
      <Button
        variant="outlined"
        color="primary"
        component={Link}
        to={paths.registerConcert()}
      >
        New
      </Button>
      <OrchestraFilter
        value={selectedOrchestras}
        onChange={handleSelectedOrchestraChange}
      />
      <MonthFilter value={selectedMonth} onChange={handleMonthChange} />
      <QueryResultHandler {...queryResult}>
        <Concerts concerts={filteredConcerts} />
      </QueryResultHandler>
    </div>
  );
};

export default ListConcerts;
