import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import { createPiece } from "graphql/mutations";
import PieceForm from "admin/components/PieceForm";
import paths from "admin/paths";

const RegisterPiece = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);
  const initialState = { composerId: queryParams.get("composer") };

  const handleSubmit = async (input) => {
    try {
      const result = await API.graphql(
        graphqlOperation(createPiece, { input })
      );
      history.push(paths.viewPiece(result.data.createPiece.id));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <h1>Register Piece</h1>
      <PieceForm initialState={initialState} onSubmit={handleSubmit} />
    </div>
  );
};

export default RegisterPiece;
