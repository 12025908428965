import React from "react";
import { TextField } from "@material-ui/core";
import useComposers from "hooks/useComposers";
import ComposerSelectorField from "./ComposerSelectorField";

const ComposerSelector = ({ ...props }) => {
  const { composers, errors, loading } = useComposers();

  if (errors) return null;

  if (loading) return <TextField {...props} value="Loading..." disabled />;

  return <ComposerSelectorField composers={composers} {...props} />;
};

export default ComposerSelector;
