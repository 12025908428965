import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { createComposer } from "graphql/mutations";
import ComposerForm from "admin/components/ComposerForm";
import paths from "admin/paths";

const RegisterComposer = () => {
  const history = useHistory();

  const handleSubmit = async (input) => {
    try {
      const result = await API.graphql(
        graphqlOperation(createComposer, { input })
      );
      history.push(paths.viewComposer(result.data.createComposer.id));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <h1>Register Composer</h1>
      <ComposerForm onSubmit={handleSubmit} />
    </div>
  );
};

export default RegisterComposer;
