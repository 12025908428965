import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Chip,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { regions, prefectures } from "./location";

const RegionGroup = ({ region, selected, onChange }) => {
  const items = prefectures.filter((p) => p.region === region.code);

  const handleChange = (e) => {
    const code = parseInt(e.target.name, 10);
    onChange(code, e.target.checked);
  };

  return (
    <div>
      <FormLabel component="legend">{region.name}</FormLabel>
      <FormGroup row>
        {items.map((item) => (
          <FormControlLabel
            key={item.code}
            control={
              <Checkbox
                checked={selected.includes(item.code)}
                onChange={handleChange}
                name={item.code.toString()}
              />
            }
            label={item.name}
          />
        ))}
      </FormGroup>
    </div>
  );
};

export const LocationFilter = ({ value, onChange, validPrefectures }) => {
  const [open, setOpen] = useState(false);
  const handleChange = (code, selected) => {
    if (selected) {
      if (!value.includes(code)) {
        const newValue = [...value, code];
        newValue.sort();
        onChange(newValue);
      }
    } else {
      onChange(value.filter((v) => v !== code));
    }
  };

  return (
    <Box mb={2}>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => setOpen(true)}
      >
        都道府県で絞り込み
      </Button>
      <Box display="flex" flexWrap="wrap">
        {value.map((code) => {
          const { name } = prefectures.find((p) => p.code === code);
          return (
            <Box key={code} mr={1} mt={1}>
              <Chip label={name} size="small" />
            </Box>
          );
        })}
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box p={2}>
          {regions.map((region) => (
            <RegionGroup
              key={region.code}
              region={region}
              selected={value}
              onChange={handleChange}
            />
          ))}
        </Box>
        <Box textAlign="center" mb={2}>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            閉じる
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};
