import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { updateComposer } from "graphql/mutations";
import useComposer from "hooks/useComposer";
import ComposerForm from "admin/components/ComposerForm";
import QueryResultHandler from "components/QueryResultHandler";
import paths from "admin/paths";

const EditComposer = () => {
  const { id } = useParams();
  const history = useHistory();
  const { composer, ...queryResult } = useComposer(id);

  const handleSubmit = async (input) => {
    input.id = id;
    try {
      await API.graphql(graphqlOperation(updateComposer, { input }));
      history.push(paths.viewComposer(id));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <h1>Edit Composer</h1>

      <QueryResultHandler {...queryResult}>
        <ComposerForm initialState={composer} onSubmit={handleSubmit} />
      </QueryResultHandler>
    </div>
  );
};

export default EditComposer;
