import { Paper, makeStyles } from "@material-ui/core";
import { format } from "date-fns";
import { Piece } from "./Piece";
import { KeyValueDataTable } from "components/KeyValueDataTable";
import { getLabelFromCode } from "utils/prefectures";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, "auto"),
    padding: theme.spacing(2),
  },
}));

export const Concert = ({ concert }) => {
  const classes = useStyles();
  const {
    nameJa,
    date,
    orchestra,
    website,
    venue,
    venuePrefecture,
    conductor,
    note,
    program,
  } = concert;

  const data = [
    { label: "日時", value: format(new Date(date), "yyyy年 M月 d日 H:mm") },
    { label: "Webサイト", value: website, link: true },
    { label: "会場", value: `${venue} (${getLabelFromCode(venuePrefecture)})` },
    { label: "指揮", value: conductor },
    { label: "備考", value: note },
  ];

  return (
    <Paper className={classes.root}>
      <h2>{nameJa}</h2>
      <h3>{orchestra.nameJa}</h3>
      <KeyValueDataTable data={data} />
      <h3>プログラム</h3>
      {program.items.map(({ piece }) => (
        <Piece key={piece.id} piece={piece} />
      ))}
    </Paper>
  );
};
