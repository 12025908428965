import React from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import PieceSelectorPane from "./PieceSelectorPane";

const PieceSelectorDialog = ({ composers, open, value, onChange, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="piece-selector-dialog-title"
    >
      <DialogTitle id="piece-selector-dialog-title">曲を選択</DialogTitle>
      <DialogContent>
        <PieceSelectorPane
          composers={composers}
          value={value}
          onChange={onChange}
        />
      </DialogContent>
    </Dialog>
  );
};

export default PieceSelectorDialog;
