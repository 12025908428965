import { Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, "auto"),
    padding: theme.spacing(2),
    maxWidth: 600,
  },
}));

export const DisclaimerPage = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <h1>本サイトのご利用条件・免責事項</h1>
      <p>
        本ウェブサイトにアクセスされる皆様は、以下の事項に同意されたものとみなされます。
      </p>
      <p>
        記載する情報の正確性については細心の注意を払っていますが、誤りがある場合や情報を収集した時点から変更されている可能性があります。最新の情報については各団体の公式サイトなどをご確認ください。
      </p>
      <p>
        プログラムの曲目や作曲者の表記は当サイトで統一されたデータベースを使用しています。したがって、各団体公式のプログラムに書かれた表記とは異なる場合があります。
      </p>
    </Paper>
  );
};
