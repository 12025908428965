import { Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, "auto"),
    padding: theme.spacing(2),
  },
}));

export const PrivacyPolicyPage = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <h1>プライバシーポリシー</h1>
      <p>
        当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を使用しています。このGoogleアナリティクスはデータの収集のためにCookieを使用しています。このデータは匿名で収集されており、個人を特定するものではありません。
      </p>
      <p>
        この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
      </p>
      <p>
        <a
          href="https://marketingplatform.google.com/about/analytics/terms/jp/"
          target="_blank"
          rel="noreferrer"
        >
          Google アナリティクス利用規約
        </a>
      </p>
      <p>
        <a
          href="https://policies.google.com/technologies/ads?hl=ja"
          target="_blank"
          rel="noreferrer"
        >
          Googleポリシーと規約
        </a>
      </p>
    </Paper>
  );
};
