import { useState, useEffect } from "react";
import { startOfMonth, endOfMonth } from "date-fns";
import { useTheme, useMediaQuery } from "@material-ui/core";
import Calendar, { getContentKey } from "components/Calendar";
import { MobileCalendar } from "components/MobileCalendar";
import { OrchestraFilter } from "components/OrchestraFilter";
import { LocationFilter, toStringCode } from "components/LocationFilter";
import useConcerts from "hooks/useConcerts";
import ConcertItem from "./ConcertItem";

const STORAGE_KEY_ORCHESTRAS = "filter/orchestras";
const STORAGE_KEY_PREFECTURES = "filter/prefectures";

const makeContents = (concerts) => {
  // 日付・時刻をkeyとするobjectに整理する
  const byDatetime = {};
  concerts.forEach((concert) => {
    const key = getContentKey(new Date(concert.date));

    if (byDatetime[key] == null) {
      byDatetime[key] = [];
    }
    byDatetime[key].push(concert);
  });

  // 日付ごとにReactNodeにする
  const contents = {};
  Object.keys(byDatetime).forEach((key) => {
    const items = byDatetime[key];
    items.sort((a, b) => a.date.localeCompare(b.date));
    contents[key] = items.map((concert) => {
      return <ConcertItem key={concert.id} concert={concert} />;
    });
  });

  return contents;
};

const ConcertCalendar = ({ month, onMonthChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedOrchestras, setSelectedOrchestras] = useState([]);
  const [selectedPrefectures, setSelectedPrefectures] = useState([]);
  const { concerts } = useConcerts({
    deep: true,
    variables: {
      filter: {
        date: {
          between: [
            startOfMonth(month).toISOString(),
            endOfMonth(month).toISOString(),
          ],
        },
        or:
          selectedPrefectures && selectedPrefectures.length > 0
            ? selectedPrefectures.map((pref) => ({
                venuePrefecture: { eq: toStringCode(pref) },
              }))
            : undefined,
      },
    },
  });

  useEffect(() => {
    // local strageから読み込む
    const storedOrchestras = localStorage.getItem(STORAGE_KEY_ORCHESTRAS);
    if (storedOrchestras) {
      setSelectedOrchestras(JSON.parse(storedOrchestras));
    }
    const storedPrefectures = localStorage.getItem(STORAGE_KEY_PREFECTURES);
    if (storedPrefectures) {
      setSelectedPrefectures(JSON.parse(storedPrefectures));
    }
  }, []);

  const handleSelectedOrchestrasChange = (value) => {
    setSelectedOrchestras(value);
    localStorage.setItem(STORAGE_KEY_ORCHESTRAS, JSON.stringify(value));
  };

  const handleSelectedPrefecturesChange = (value) => {
    setSelectedPrefectures(value);
    localStorage.setItem(STORAGE_KEY_PREFECTURES, JSON.stringify(value));
  };

  if (concerts == null) {
    return <div>Error</div>;
  }

  let filteredConcerts = concerts;

  if (selectedOrchestras.length > 0) {
    filteredConcerts = concerts.filter((concert) =>
      selectedOrchestras.includes(concert.orchestra.id)
    );
  }

  const contents = makeContents(filteredConcerts);

  return (
    <div>
      <OrchestraFilter
        value={selectedOrchestras}
        onChange={handleSelectedOrchestrasChange}
      />
      <LocationFilter
        value={selectedPrefectures}
        onChange={handleSelectedPrefecturesChange}
      />
      {isMobile ? (
        <MobileCalendar
          targetMonth={month}
          contents={contents}
          onMonthChange={onMonthChange}
        />
      ) : (
        <Calendar
          targetMonth={month}
          contents={contents}
          onMonthChange={onMonthChange}
        />
      )}
    </div>
  );
};

export default ConcertCalendar;
