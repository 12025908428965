import { listConcerts } from "graphql/queries";
import useQuery from "./useQuery";

const listConcertsDeep = /* GraphQL */ `
  query ListConcertsDeep(
    $filter: ModelConcertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConcerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orchestraId
        orchestra {
          id
          nameJa
          nameEn
          website
          concerts {
            items {
              id
              orchestraId
              nameJa
              nameEn
              date
              venue
              venuePrefecture
              website
              note
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nameJa
        nameEn
        program {
          items {
            id
            concertId
            pieceId
            piece {
              id
              nameJa
              longNameJa
              nameEn
              longNameEn
              composerId
              composer {
                id
                nameJa
                longNameJa
                nameLatin
                longNameLatin
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            order
            createdAt
            updatedAt
          }
          nextToken
        }
        date
        venue
        venuePrefecture
        website
        conductor
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

const useConcerts = ({ deep = false, variables = null }) => {
  const { data, errors, loading } = useQuery(
    deep ? listConcertsDeep : listConcerts,
    { limit: 1000, ...variables }
  );

  const concerts = data?.listConcerts?.items ?? [];

  // dateはこの時点で文字列。多分文字列比較で大丈夫？
  concerts.sort((a, b) => a.date.localeCompare(b.date));

  return { concerts, errors, loading };
};

export default useConcerts;
