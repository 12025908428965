import { MenuItem, TextField } from "@material-ui/core";
import { prefectures } from "utils/prefectures";

export const PrefectureSelector = ({ ...props }) => {
  return (
    <TextField select {...props}>
      {prefectures.map((prefecture) => (
        <MenuItem key={prefecture.value} value={prefecture.value}>
          {prefecture.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
