import { listOrchestras } from "graphql/queries";
import useQuery from "./useQuery";

const useOrchestras = () => {
  const { data, errors, loading } = useQuery(listOrchestras);

  const orchestras = data?.listOrchestras?.items ?? [];

  orchestras.sort((a, b) => a.nameJa.localeCompare(b.nameJa));

  return { orchestras, errors, loading };
};

export default useOrchestras;
