import { API, graphqlOperation } from "aws-amplify";
import { listComposers } from "graphql/queries";
import yaml from "js-yaml";
import downloadFile from "./download-file";
import { timestamp } from "./timestamp";

export const exportComposersAndPieces = async () => {
  try {
    const result = await API.graphql(
      graphqlOperation(listComposers, { limit: 1000 })
    );
    if (result.errors) throw result.errors;
    const composers = result.data.listComposers.items;

    // 必要なデータだけ取り出す
    const data = composers.map((composer) => {
      const { id, nameJa, longNameJa, nameLatin, longNameLatin } = composer;

      const pieces = composer.pieces.items.map((piece) => {
        const { id, nameJa, longNameJa, nameEn, longNameEn } = piece;
        return { id, nameJa, longNameJa, nameEn, longNameEn };
      });

      return {
        id,
        nameJa,
        longNameJa,
        nameLatin,
        longNameLatin,
        pieces,
      };
    });

    downloadFile(yaml.dump(data), `composers-exported-${timestamp()}.yml`);
  } catch (err) {
    console.log(err);
  }
};

export default exportComposersAndPieces;
