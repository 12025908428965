import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { updatePiece } from "graphql/mutations";
import usePiece from "hooks/usePiece";
import PieceForm from "admin/components/PieceForm";
import QueryResultHandler from "components/QueryResultHandler";
import paths from "admin/paths";

const EditPiece = () => {
  const { id } = useParams();
  const history = useHistory();
  const { piece, ...queryResult } = usePiece(id);

  const handleSubmit = async (input) => {
    input.id = id;
    try {
      await API.graphql(graphqlOperation(updatePiece, { input }));
      history.push(paths.viewPiece(id));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <h1>Edit Piece</h1>

      <QueryResultHandler {...queryResult}>
        <PieceForm initialState={piece} lockComposer onSubmit={handleSubmit} />
      </QueryResultHandler>
    </div>
  );
};

export default EditPiece;
