import React from "react";
import { TextField } from "@material-ui/core";
import useOrchestras from "hooks/useOrchestras";
import OrchestraSelectorField from "./OrchestraSelectorField";

const OrchestraSelector = ({ ...props }) => {
  const { orchestras, errors, loading } = useOrchestras();

  if (errors) return null;

  if (loading) return <TextField {...props} value="Loading..." disabled />;

  return <OrchestraSelectorField orchestras={orchestras} {...props} />;
};

export default OrchestraSelector;
