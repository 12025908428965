import { API, graphqlOperation } from "aws-amplify";
import { listOrchestras } from "graphql/queries";
import yaml from "js-yaml";
import downloadFile from "./download-file";
import { timestamp } from "./timestamp";

export const exportOrchestras = async () => {
  try {
    const result = await API.graphql(graphqlOperation(listOrchestras));
    if (result.errors) throw result.errors;
    const orchestras = result.data.listOrchestras.items;

    // 必要なデータだけ取り出す
    const data = orchestras.map((orchestra) => {
      const { id, nameJa, nameEn, website } = orchestra;
      return {
        id,
        nameJa,
        nameEn,
        website,
      };
    });

    downloadFile(yaml.dump(data), `orchestras-exported-${timestamp()}.yml`);
  } catch (err) {
    console.log(err);
  }
};

export default exportOrchestras;
