export const GA_ID =
  process.env.NODE_ENV === "production" ? "UA-193496595-1" : null;

// PVを測定する
export const pageview = (path) => {
  if (!GA_ID) {
    return;
  }

  window.gtag("config", GA_ID, {
    page_path: path,
  });
};

// GAイベントを発火させる
export const event = ({ action, category, label, value = "" }) => {
  if (!GA_ID) {
    return;
  }

  window.gtag("event", action, {
    event_category: category,
    event_label: JSON.stringify(label),
    value,
  });
};
