import { getPiece } from "graphql/queries";
import useQuery from "./useQuery";

const usePiece = (id) => {
  const { data, errors, loading } = useQuery(getPiece, { id });

  const piece = data?.getPiece ?? null;

  return { piece, errors, loading };
};

export default usePiece;
